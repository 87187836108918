import { useMemo } from 'react';

import { ApolloClient, ApolloError, useQuery } from '@apollo/client';
import { GET_ORGANISATION_DETAILS, GET_ORGANISATION_SLUG } from 'src/components/organisations/organisations-queries';
import {
  GetOrganisationSlugQuery,
  GetOrganisationSlugQueryVariables,
  SaasOrganisation,
} from 'src/graphql-types/usta-mesh/graphql';
import { Venue } from 'src/hooks/use-section-and-districts';

import { meshGatewayClient } from './client';
import { GET_SELECTED_FACILITY, GET_VENUES } from './queries';

export const setSelectedFacility = (fac, client: ApolloClient<object>) => {
  client.writeQuery({
    query: GET_SELECTED_FACILITY,
    data: { selectedFacility: JSON.stringify(fac) },
  });
};

export const useSelectedFacility = () => {
  const { data: facilityData } = useQuery(GET_SELECTED_FACILITY);
  return useMemo(() => facilityData?.selectedFacility && JSON.parse(facilityData.selectedFacility), [facilityData]);
};

export const useSelectedFacilitysTenantAccessSettings = () => {
  const { Settings: tenantSettings } = useSelectedFacility() ?? {};
  return useMemo(
    () => ({
      isNationalAdminAccessEnabled: !!tenantSettings?.EnableNationalAdminAccess,
      isRegionalAdminAccessEnabled: !!tenantSettings?.EnableRegionalAdminAccess,
    }),
    [tenantSettings],
  );
};

export const useOrgId = () => useSelectedFacility()?.VenueID;

export const useOrgSlug = (orgId: string) => {
  const { data: organisation } = useQuery<GetOrganisationSlugQuery, GetOrganisationSlugQueryVariables>(
    GET_ORGANISATION_SLUG,
    {
      client: meshGatewayClient,
      variables: {
        organisationId: orgId,
      },
    },
  );
  return useMemo(() => organisation?.getOrganisationById?.slug, [organisation]);
};

export const useGetFacilityDetails = (orgIds: string[], onError?: (err: ApolloError) => void) => {
  const { data, loading } = useQuery(GET_ORGANISATION_DETAILS, {
    client: meshGatewayClient,
    variables: {
      organisationIds: orgIds,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    skip: orgIds.length <= 0,
    onError,
  });
  return useMemo(() => {
    return [data?.getOrganisationsByIds?.filter((org: SaasOrganisation) => org !== null), loading];
  }, [data?.getOrganisationsByIds, loading]);
};

export const getOrgId = (client: ApolloClient<object>) => {
  const facilityData = client.readQuery({ query: GET_SELECTED_FACILITY });
  return facilityData?.selectedFacility && JSON.parse(facilityData.selectedFacility).VenueID;
};

export const useOrgName = () => useSelectedFacility()?.Name;

export const setVenues = (venues: Venue[], client: ApolloClient<object>) => {
  client.writeQuery({ query: GET_VENUES, data: { venues: JSON.stringify(venues) } });
};

export const useVenues = () => {
  const { data: venuesData } = useQuery(GET_VENUES);
  return useMemo(() => venuesData?.venues && JSON.parse(venuesData?.venues), [venuesData]);
};
