import graphql from 'graphql-tag';

export const FIND_VENUES = graphql`
  query FindVenues($filter: OrganisationFilterOptionsInput, $sort: SortOrder) {
    findOrganisations(filter: $filter, pageArgs: { skip: 0, limit: 5 }, sort: $sort) {
      results {
        organisationName
        organisationId
      }
      total
    }
  }
`;

export const FIND_ORGANISATIONS = graphql`
  query FindOrganisations($pageArgs: PaginationArgs, $filter: OrganisationFilterOptionsInput, $sort: SortOrder) {
    findOrganisations(filter: $filter, pageArgs: $pageArgs, sort: $sort) {
      results {
        id: organisationId
        organisationName
        organisationId
        organisationType
        externalId
        csFriendlyId
        parentOrganisation {
          organisationName
          organisationId
          organisationType
        }
        addresses {
          addressType
          addressLine1
          state
          city
          postalCode
          addressName
        }
        additionalFields
        status
      }
      total
    }
  }
`;

export const FIND_SCHOOL_ORGANISATIONS = graphql`
  query FindSchoolOrganisations($pageArgs: PaginationArgs, $filter: OrganisationFilterOptionsInput, $sort: SortOrder) {
    findOrganisations(filter: $filter, pageArgs: $pageArgs, sort: $sort) {
      results {
        organisationName
        organisationId
        externalId
        claimStatus
        claimStatusUpdatedAt
        inviteRecipientEmail
        addresses {
          city
          state
          postalCode
        }
        contacts {
          standardFamilyName
          standardGivenName
          phoneNumber
          email
        }
      }
      total
    }
  }
`;
export const GET_ORGANISATION_SLUG = graphql`
  query GetOrganisationSlug($organisationId: String!) {
    getOrganisationById(organisationId: $organisationId) {
      slug
    }
  }
`;

export const GET_ORGANISATION_DETAILS = graphql`
  query GetOrganisationDetails($organisationIds: [String!]!) {
    getOrganisationsByIds(organisationIds: $organisationIds) {
      organisationName
      organisationType
      organisationId
      parentOrganisation {
        organisationName
        parentOrganisation {
          organisationName
        }
      }
      nationalAssociationSubType
    }
  }
`;
export const SEARCH_ORGANISATIONS = graphql`
  query SearchOrganisations($pageArgs: PaginationArgs, $filter: OrganisationFilterOptionsInput!) {
    findOrganisations(filter: $filter, pageArgs: $pageArgs) {
      results {
        organisationName
        organisationType
        organisationId
        parentOrganisation {
          organisationName
          parentOrganisation {
            organisationName
          }
        }
        nationalAssociationSubType
      }
    }
  }
`;
export const INVITE_USER_TO_CLAIM_ORGANISATION = graphql`
  mutation InviteUserToClaimOrganisation($emailOrExternalId: String!, $organisationId: String!, $targetUrl: String!) {
    inviteUserToClaimOrganisation(
      emailOrExternalId: $emailOrExternalId
      organisationId: $organisationId
      targetUrl: $targetUrl
    ) {
      inviteExpiry
      inviteKey
      inviteUrl
    }
  }
`;
export const UNCLAIM_ORGANISATION = graphql`
  mutation UnclaimOrganisation($organisationId: String!) {
    unclaimOrganisation(organisationId: $organisationId) {
      organisationId
    }
  }
`;

export const GET_ORGANISATION_PROFILE = graphql`
  query GetOrganisationProfile($organisationId: String!) {
    getOrganisationById(organisationId: $organisationId) {
      organisationName
      organisationId
      organisationType
      externalId
      csFriendlyId
      parentOrganisation {
        organisationName
        organisationId
      }
      addresses {
        addressType
        addressLine1
        state
        city
        postalCode
        addressName
      }
      additionalFields
      status
    }
  }
`;

export const GET_ALL_REGIONS = graphql`
  query GetAllRegions($rootOrgId: String, $sort: SortOrder) {
    findOrganisations(
      filter: { parentOrganisationId: $rootOrgId, organisationType: { eq: NATIONAL_ASSOCIATION } }
      sort: $sort
    ) {
      results {
        id: organisationId
        organisationName
        parentOrganisation {
          organisationName
          organisationId
        }
      }
    }
  }
`;
