/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: string; output: string };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: {}; output: {} };
};

export type AddFulfilmentProviderCommandInput = {
  contactEmail: Scalars['String']['input'];
  contactName: Scalars['String']['input'];
  description: Scalars['String']['input'];
  identifierPrefix: Scalars['String']['input'];
  name: Scalars['String']['input'];
  processingDelayTicks: Scalars['Float']['input'];
  shortCode: Scalars['String']['input'];
};

export type AddProductCommandFileInput = {
  name: InputMaybe<Scalars['String']['input']>;
  url: InputMaybe<Scalars['String']['input']>;
};

export type AddProductCommandInput = {
  description: InputMaybe<Scalars['String']['input']>;
  externalId: InputMaybe<Scalars['String']['input']>;
  files: InputMaybe<Array<InputMaybe<AddProductCommandFileInput>>>;
  groupIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  inventory: InputMaybe<AddProductInventoryInput>;
  maxPerCustomer: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  price: InputMaybe<Scalars['Int']['input']>;
  productParentIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  productTypeId: Scalars['ID']['input'];
  propertyValueIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  sku: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<ProductStatus>;
  variantGroup: InputMaybe<AddProductVariantGroupInput>;
};

export type AddProductInventoryInput = {
  fulfilmentProviderId: InputMaybe<Scalars['ID']['input']>;
  stockCount: InputMaybe<Scalars['Int']['input']>;
};

export type AddProductPriceCommandInput = {
  customKey: InputMaybe<Scalars['String']['input']>;
  installmentCount: InputMaybe<Scalars['Int']['input']>;
  installmentDay: InputMaybe<Scalars['Int']['input']>;
  installmentDayIsDateOfPurchase: InputMaybe<Scalars['Boolean']['input']>;
  installmentFrequency: InputMaybe<Scalars['String']['input']>;
  installmentProRataEnabled: InputMaybe<Scalars['Boolean']['input']>;
  isRenewal: InputMaybe<Scalars['Boolean']['input']>;
  price: InputMaybe<Scalars['Int']['input']>;
  productId: Scalars['ID']['input'];
  type: InputMaybe<ProductPriceType>;
};

export type AddProductPropertyCommandFileInput = {
  name: InputMaybe<Scalars['String']['input']>;
  url: InputMaybe<Scalars['String']['input']>;
};

export type AddProductPropertyCommandInput = {
  files: InputMaybe<Array<InputMaybe<AddProductPropertyCommandFileInput>>>;
  input: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  required: InputMaybe<Scalars['Boolean']['input']>;
  sortIndex: InputMaybe<Scalars['Int']['input']>;
  values: InputMaybe<Array<InputMaybe<AddProductPropertyCommandValueInput>>>;
};

export type AddProductPropertyCommandValueInput = {
  sortIndex: InputMaybe<Scalars['Int']['input']>;
  value: InputMaybe<Scalars['String']['input']>;
};

export type AddProductVariantGroupInput = {
  id: InputMaybe<Scalars['ID']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  sortIndex: InputMaybe<Scalars['Int']['input']>;
};

export type AssociateParkSiteInput = {
  localAuhtorityId: Scalars['String']['input'];
  operation: Scalars['String']['input'];
  parkSiteId: Scalars['String']['input'];
};

export enum BasketStatus {
  DOESNOTEXIST = 'DOESNOTEXIST',
  PROCESSED = 'PROCESSED',
  UNPROCESSED = 'UNPROCESSED',
}

export type BasketStatusQueryOutput = {
  __typename?: 'BasketStatusQueryOutput';
  basketStatus: Maybe<BasketStatus>;
  orders: Maybe<Array<Maybe<BasketStatusQueryOutputOrder>>>;
};

export type BasketStatusQueryOutputOrder = {
  __typename?: 'BasketStatusQueryOutputOrder';
  checkoutBasketCommandOutput: Maybe<CheckoutBasketCommandOutput>;
  id: Maybe<Scalars['ID']['output']>;
  initialPaymentMethodId: Maybe<Scalars['ID']['output']>;
  orderPayStatusId: Maybe<Scalars['ID']['output']>;
};

export type CanBeCancelledQueryOutput = {
  __typename?: 'CanBeCancelledQueryOutput';
  canBeCancelled: Maybe<Scalars['Boolean']['output']>;
  reason: Maybe<Scalars['String']['output']>;
};

export type CanBeRefundedQueryOutput = {
  __typename?: 'CanBeRefundedQueryOutput';
  canBeRefunded: Maybe<Scalars['Boolean']['output']>;
  canBeRefundedWithPaymentMethodCreditDebitCard: Maybe<Scalars['Boolean']['output']>;
  reason: Maybe<Scalars['String']['output']>;
  reasonCode: Maybe<Scalars['String']['output']>;
  reasons: Maybe<Array<Maybe<CanBeRefundedQueryOutputReason>>>;
  supportedPaymentMethodIds: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type CanBeRefundedQueryOutputReason = {
  __typename?: 'CanBeRefundedQueryOutputReason';
  reason: Maybe<Scalars['String']['output']>;
  reasonCode: Maybe<Scalars['String']['output']>;
};

export type CanOrderLineItemsByProductTypeBePaidCashOutput = {
  __typename?: 'CanOrderLineItemsByProductTypeBePaidCashOutput';
  amount: Maybe<Scalars['Int']['output']>;
  canBePaid: Maybe<Scalars['Boolean']['output']>;
  reason: Maybe<Scalars['String']['output']>;
  supportedPaymentMethodIds: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type CancelLineItemsByProductTypeForOrderCommandInput = {
  orderId: InputMaybe<Scalars['ID']['input']>;
  productTypeId: InputMaybe<Scalars['ID']['input']>;
};

export type CancelMyLineItemsByProductTypeForOrderCommandInput = {
  orderId: InputMaybe<Scalars['ID']['input']>;
  productTypeId: InputMaybe<Scalars['ID']['input']>;
};

export type CancelOrderLineItemCommandInput = {
  orderLineItemId: InputMaybe<Scalars['ID']['input']>;
  quantity: InputMaybe<Scalars['Int']['input']>;
};

export type CancelProductSubscriptionCommandInput = {
  productSubscriptionId: InputMaybe<Scalars['ID']['input']>;
};

export type CheckoutAutoPaymentBasketCommandInput = {
  accountId: InputMaybe<Scalars['ID']['input']>;
  chargeDate: InputMaybe<Scalars['String']['input']>;
  customerId: InputMaybe<Scalars['ID']['input']>;
  disableInstallmentDayFromProductPrice: InputMaybe<Scalars['Boolean']['input']>;
  idempotencyKey: Scalars['ID']['input'];
  lineItems: Array<InputMaybe<CheckoutBasketCommandLineItemInput>>;
  organisationId: InputMaybe<Scalars['ID']['input']>;
  paymentMethodId: InputMaybe<Scalars['ID']['input']>;
  venueContact: InputMaybe<CheckoutAutoPaymentBasketCommandVenueContactInput>;
};

export type CheckoutAutoPaymentBasketCommandVenueContactInput = {
  address1: InputMaybe<Scalars['String']['input']>;
  address2: InputMaybe<Scalars['String']['input']>;
  address3: InputMaybe<Scalars['String']['input']>;
  classicUserId: InputMaybe<Scalars['ID']['input']>;
  classicVenueContactId: Scalars['ID']['input'];
  country: InputMaybe<Scalars['String']['input']>;
  county: InputMaybe<Scalars['String']['input']>;
  createdByClassicUserId: InputMaybe<Scalars['ID']['input']>;
  email: Scalars['String']['input'];
  firstName: InputMaybe<Scalars['String']['input']>;
  goCardlessV2MandateID: InputMaybe<Scalars['String']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  mobile: InputMaybe<Scalars['String']['input']>;
  postcode: InputMaybe<Scalars['String']['input']>;
  telephone: InputMaybe<Scalars['String']['input']>;
  town: InputMaybe<Scalars['String']['input']>;
};

export type CheckoutBasketCommandCustomerInput = {
  address1: InputMaybe<Scalars['String']['input']>;
  address2: InputMaybe<Scalars['String']['input']>;
  address3: InputMaybe<Scalars['String']['input']>;
  countryId: InputMaybe<Scalars['ID']['input']>;
  county: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  mobile: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  phone: InputMaybe<Scalars['String']['input']>;
  postcode: InputMaybe<Scalars['String']['input']>;
  surname: InputMaybe<Scalars['String']['input']>;
  town: InputMaybe<Scalars['String']['input']>;
};

export type CheckoutBasketCommandInput = {
  cancelUrl: Scalars['String']['input'];
  customer: CheckoutBasketCommandCustomerInput;
  customerPaymentMethodExternalId: InputMaybe<Scalars['String']['input']>;
  idempotencyKey: Scalars['ID']['input'];
  lineItems: Array<InputMaybe<CheckoutBasketCommandLineItemInput>>;
  paymentMethodId: InputMaybe<Scalars['ID']['input']>;
  statementDescriptorSuffix: InputMaybe<Scalars['String']['input']>;
  successUrl: Scalars['String']['input'];
  venueAccountIdOverride: InputMaybe<Scalars['ID']['input']>;
};

export type CheckoutBasketCommandLineItemInput = {
  applicationFeeFixedAmountOverride: InputMaybe<Scalars['Int']['input']>;
  installmentCount: InputMaybe<Scalars['Int']['input']>;
  installmentDay: InputMaybe<Scalars['Int']['input']>;
  installmentDayIsDateOfPurchase: InputMaybe<Scalars['Boolean']['input']>;
  installmentFrequency: InputMaybe<Scalars['String']['input']>;
  installmentProRataEnabled: InputMaybe<Scalars['Boolean']['input']>;
  isReorder: InputMaybe<Scalars['Boolean']['input']>;
  metadata: InputMaybe<Scalars['JSON']['input']>;
  oldSubscriptionId: InputMaybe<Scalars['ID']['input']>;
  price: InputMaybe<Scalars['Int']['input']>;
  priceId: InputMaybe<Scalars['ID']['input']>;
  priceUnixTimeMilliseconds: InputMaybe<Scalars['Float']['input']>;
  productId: Scalars['ID']['input'];
  productPriceType: InputMaybe<ProductPriceType>;
  quantity: Scalars['Int']['input'];
};

export type CheckoutBasketCommandOutput = {
  __typename?: 'CheckoutBasketCommandOutput';
  checkoutPageUrl: Maybe<Scalars['String']['output']>;
  createProductSubscriptionResponse: Maybe<CreateProductSubscriptionResponse>;
  orderId: Maybe<Scalars['ID']['output']>;
  stripeCheckoutSessionId: Maybe<Scalars['String']['output']>;
  stripePublicKey: Maybe<Scalars['String']['output']>;
};

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  allow_promotion_codes: Maybe<Scalars['String']['output']>;
  amount_subtotal: Maybe<Scalars['Float']['output']>;
  amount_total: Maybe<Scalars['Float']['output']>;
  billing_address_collection: Maybe<Scalars['String']['output']>;
  cancel_url: Maybe<Scalars['String']['output']>;
  client_reference_id: Maybe<Scalars['String']['output']>;
  currency: Maybe<Scalars['String']['output']>;
  customer: Maybe<Scalars['String']['output']>;
  customer_email: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  livemode: Maybe<Scalars['Boolean']['output']>;
  locale: Maybe<Scalars['String']['output']>;
  metadata: Maybe<TransactionMetadataOutput>;
  mode: Maybe<Scalars['String']['output']>;
  object: Maybe<Scalars['String']['output']>;
  payment_intent: Maybe<Scalars['String']['output']>;
  payment_method_types: Maybe<Array<Scalars['String']['output']>>;
  payment_status: Maybe<Scalars['String']['output']>;
  setup_intent: Maybe<Scalars['String']['output']>;
  shipping: Maybe<Scalars['String']['output']>;
  shipping_address_collection: Maybe<Scalars['String']['output']>;
  submit_type: Maybe<Scalars['String']['output']>;
  subscription: Maybe<Scalars['String']['output']>;
  success_url: Maybe<Scalars['String']['output']>;
  total_details: Maybe<TotalDetails>;
};

/** Stripe Checkout Session ID */
export type CheckoutSessionID = {
  __typename?: 'CheckoutSessionID';
  checkoutSessionID: Scalars['String']['output'];
};

/** Stripe Checkout Session ID */
export type CheckoutSessionIDInput = {
  checkoutSessionID: InputMaybe<Scalars['String']['input']>;
  isZeroCost: InputMaybe<Scalars['Boolean']['input']>;
  memberID: Scalars['String']['input'];
  membershipPackageID: Scalars['String']['input'];
  parksIDList: InputMaybe<Array<Scalars['String']['input']>>;
  salesforceID: Scalars['String']['input'];
};

export type CheckoutSessionInput = {
  email: Scalars['String']['input'];
  firstName: InputMaybe<Scalars['String']['input']>;
  lastName: InputMaybe<Scalars['String']['input']>;
  memberID: Scalars['ID']['input'];
  metadata: TransactionMetadataInput;
  mobileNumber: InputMaybe<Scalars['String']['input']>;
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  requestID: Scalars['ID']['input'];
};

export type CheckoutSessionResponse = {
  __typename?: 'CheckoutSessionResponse';
  orderId: Scalars['ID']['output'];
  stripeCheckoutSessionId: Maybe<Scalars['String']['output']>;
  stripePublicKey: Maybe<Scalars['String']['output']>;
};

/** ContactPersonalAddress */
export type ContactPersonalAddress = {
  __typename?: 'ContactPersonalAddress';
  isPrimaryMVG: Maybe<Scalars['Boolean']['output']>;
  personalAddressNameIntegration: Maybe<Scalars['String']['output']>;
  personalPostalCodeIntegration: Maybe<Scalars['String']['output']>;
  personalStreetAddress2Integration: Maybe<Scalars['String']['output']>;
  personalStreetAddress3Integration: Maybe<Scalars['String']['output']>;
  personalStreetAddressIntegration: Maybe<Scalars['String']['output']>;
  primaryAddressCity: Maybe<Scalars['String']['output']>;
  primaryAddressCountry: Maybe<Scalars['String']['output']>;
  primaryAddressCounty: Maybe<Scalars['String']['output']>;
};

export enum ContactsForOrgKey {
  CoachContact = 'CoachContact',
  MainContact = 'MainContact',
  Management = 'Management',
  WelfareContact = 'WelfareContact',
}

export type CreateContact = {
  __typename?: 'CreateContact';
  contactId: Scalars['String']['output'];
};

export type CreateContactInput = {
  dateOfBirth: Scalars['String']['input'];
  emailAddress: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type CreateProductSubscriptionAndOrderFromExistingCommandInput = {
  cancelExistingSubscription: InputMaybe<Scalars['Boolean']['input']>;
  existingProductSubscriptionId: Scalars['ID']['input'];
  newInstallmentCost: InputMaybe<Scalars['Int']['input']>;
  newOrderId: Scalars['ID']['input'];
  newProductSubscriptionId: Scalars['ID']['input'];
};

export enum CreateProductSubscriptionPaymentStatus {
  FAILED = 'FAILED',
  PAYMENTMETHODREQUIRESACTION = 'PAYMENTMETHODREQUIRESACTION',
  REQUIRESNEWPAYMENTMETHOD = 'REQUIRESNEWPAYMENTMETHOD',
  SUCCESS = 'SUCCESS',
}

export type CreateProductSubscriptionResponse = {
  __typename?: 'CreateProductSubscriptionResponse';
  status: Maybe<CreateProductSubscriptionPaymentStatus>;
  statusMetaData: Maybe<Scalars['JSON']['output']>;
};

export type EntityReferenceDto = {
  __typename?: 'EntityReferenceDto';
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

export type Facility = {
  __typename?: 'Facility';
  courtQuantity: Maybe<Scalars['Int']['output']>;
  facilityDetail: Maybe<Scalars['String']['output']>;
  facilityInformation: Maybe<Scalars['String']['output']>;
  facilityName: Maybe<Scalars['String']['output']>;
  facilityType: Maybe<Scalars['String']['output']>;
  formattedFacilityName: Maybe<Scalars['String']['output']>;
  location: Maybe<Scalars['String']['output']>;
  surface: Maybe<Scalars['String']['output']>;
};

export enum Gender {
  Female = 'Female',
  Male = 'Male',
}

export type GetBasicChildProductsInfoByParentProductIdQueryOutput = {
  __typename?: 'GetBasicChildProductsInfoByParentProductIdQueryOutput';
  hasChildProducts: Maybe<Scalars['Boolean']['output']>;
  productId: Maybe<Scalars['ID']['output']>;
};

export type GetChildProductsByParentProductIdQueryOutput = {
  __typename?: 'GetChildProductsByParentProductIdQueryOutput';
  products: Maybe<Array<Maybe<GetChildProductsByParentProductIdQueryOutputProduct>>>;
};

export type GetChildProductsByParentProductIdQueryOutputProduct = {
  __typename?: 'GetChildProductsByParentProductIdQueryOutputProduct';
  description: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  maxPerCustomer: Maybe<Scalars['Int']['output']>;
  name: Maybe<Scalars['String']['output']>;
  price: Maybe<Scalars['Int']['output']>;
  sku: Maybe<Scalars['String']['output']>;
};

export type GetContactsForOrganisation = {
  __typename?: 'GetContactsForOrganisation';
  adultOnlyCoach: Maybe<Scalars['String']['output']>;
  coachRenewalEndDate: Maybe<Scalars['String']['output']>;
  coachSafeguarding: Maybe<Scalars['String']['output']>;
  contactId: Maybe<Scalars['String']['output']>;
  dbsApplicationStatus: Maybe<Scalars['String']['output']>;
  dbsExpiryDate: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  hours: Maybe<Scalars['String']['output']>;
  isCoachValid: Maybe<Scalars['String']['output']>;
  isDBSValid: Maybe<Scalars['Boolean']['output']>;
  isWelfareSWITValid: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  ltaNumber: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  qualificationLevel: Maybe<Scalars['String']['output']>;
  roles: Maybe<Array<Scalars['String']['output']>>;
  status: Maybe<Scalars['String']['output']>;
  typeOfDBSCheck: Maybe<Scalars['String']['output']>;
  welfareSafeguarding: Maybe<Scalars['String']['output']>;
};

export type GetCountriesQueryOutput = {
  __typename?: 'GetCountriesQueryOutput';
  countries: Maybe<Array<Maybe<GetCountriesQueryOutputCountry>>>;
};

export type GetCountriesQueryOutputCountry = {
  __typename?: 'GetCountriesQueryOutputCountry';
  alpha2: Maybe<Scalars['String']['output']>;
  alpha3: Maybe<Scalars['String']['output']>;
  defaultIsoCurrencyId: Maybe<Scalars['ID']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

export type GetCustomerPaymentMethodsQueryOutput = {
  __typename?: 'GetCustomerPaymentMethodsQueryOutput';
  customerPaymentMethods: Maybe<Array<Maybe<GetCustomerPaymentMethodsQueryOutputCustomerPaymentMethod>>>;
};

export type GetCustomerPaymentMethodsQueryOutputCustomerPaymentMethod = {
  __typename?: 'GetCustomerPaymentMethodsQueryOutputCustomerPaymentMethod';
  brand: Maybe<Scalars['String']['output']>;
  createdDateTime: Maybe<Scalars['String']['output']>;
  expiryMonth: Maybe<Scalars['Int']['output']>;
  expiryYear: Maybe<Scalars['Int']['output']>;
  externalId: Maybe<Scalars['String']['output']>;
  last4: Maybe<Scalars['String']['output']>;
};

export type GetGoCardlessPaymentDetailsForOrderQueryOutput = {
  __typename?: 'GetGoCardlessPaymentDetailsForOrderQueryOutput';
  results: Maybe<Array<Maybe<GetGoCardlessPaymentDetailsForOrderQueryResult>>>;
};

export type GetGoCardlessPaymentDetailsForOrderQueryResult = {
  __typename?: 'GetGoCardlessPaymentDetailsForOrderQueryResult';
  paymentDetails: Maybe<GetGoCardlessPaymentDetailsQueryOutput>;
  transactionId: Maybe<Scalars['ID']['output']>;
};

export type GetGoCardlessPaymentDetailsQueryOutput = {
  __typename?: 'GetGoCardlessPaymentDetailsQueryOutput';
  payment: Maybe<GoCardlessPayment>;
};

export type GetGoCardlessSubscriptionDetailsForOrderQueryOutput = {
  __typename?: 'GetGoCardlessSubscriptionDetailsForOrderQueryOutput';
  results: Maybe<Array<Maybe<GetGoCardlessSubscriptionDetailsForOrderQueryResult>>>;
};

export type GetGoCardlessSubscriptionDetailsForOrderQueryResult = {
  __typename?: 'GetGoCardlessSubscriptionDetailsForOrderQueryResult';
  productSubscriptionId: Maybe<Scalars['ID']['output']>;
  subscriptionDetails: Maybe<GetGoCardlessSubscriptionDetailsQueryOutput>;
};

export type GetGoCardlessSubscriptionDetailsQueryOutput = {
  __typename?: 'GetGoCardlessSubscriptionDetailsQueryOutput';
  payments: Maybe<Array<Maybe<GoCardlessPayment>>>;
  subscription: Maybe<GoCardlessSubscription>;
};

export type GetHierarchyChildrenByProductIdQueryOutput = {
  __typename?: 'GetHierarchyChildrenByProductIdQueryOutput';
  products: Maybe<Array<Maybe<GetHierarchyChildrenByProductIdQueryOutputProduct>>>;
};

export type GetHierarchyChildrenByProductIdQueryOutputFile = {
  __typename?: 'GetHierarchyChildrenByProductIdQueryOutputFile';
  name: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type GetHierarchyChildrenByProductIdQueryOutputInventory = {
  __typename?: 'GetHierarchyChildrenByProductIdQueryOutputInventory';
  stockCount: Maybe<Scalars['Int']['output']>;
};

export type GetHierarchyChildrenByProductIdQueryOutputProduct = {
  __typename?: 'GetHierarchyChildrenByProductIdQueryOutputProduct';
  description: Maybe<Scalars['String']['output']>;
  files: Maybe<Array<Maybe<GetHierarchyChildrenByProductIdQueryOutputFile>>>;
  id: Maybe<Scalars['ID']['output']>;
  inventory: Maybe<GetHierarchyChildrenByProductIdQueryOutputInventory>;
  maxPerCustomer: Maybe<Scalars['Int']['output']>;
  name: Maybe<Scalars['String']['output']>;
  price: Maybe<Scalars['Int']['output']>;
  properties: Maybe<Array<Maybe<GetHierarchyChildrenByProductIdQueryOutputProperty>>>;
  sku: Maybe<Scalars['String']['output']>;
  variantGroupId: Maybe<Scalars['ID']['output']>;
  variantGroupSortIdex: Maybe<Scalars['Int']['output']>;
};

export type GetHierarchyChildrenByProductIdQueryOutputProperty = {
  __typename?: 'GetHierarchyChildrenByProductIdQueryOutputProperty';
  property: Maybe<Scalars['String']['output']>;
  propertyId: Maybe<Scalars['ID']['output']>;
  propertySortIndex: Maybe<Scalars['Int']['output']>;
  propertyValue: Maybe<Scalars['String']['output']>;
  propertyValueId: Maybe<Scalars['ID']['output']>;
  propertyValueRequired: Maybe<Scalars['Boolean']['output']>;
  propertyValueSortIndex: Maybe<Scalars['Int']['output']>;
};

export type GetHowdensURL = {
  __typename?: 'GetHowdensURL';
  completedDate: Maybe<Scalars['String']['output']>;
  howdensURL: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  riskAssessmentStatus: Maybe<Scalars['String']['output']>;
};

export type GetOrSetProductPriceCommandInput = {
  customKey: InputMaybe<Scalars['String']['input']>;
  installmentCount: InputMaybe<Scalars['Int']['input']>;
  installmentDay: InputMaybe<Scalars['Int']['input']>;
  installmentDayIsDateOfPurchase: InputMaybe<Scalars['Boolean']['input']>;
  installmentFrequency: InputMaybe<Scalars['String']['input']>;
  installmentProRataEnabled: InputMaybe<Scalars['Boolean']['input']>;
  isRenewal: InputMaybe<Scalars['Boolean']['input']>;
  price: InputMaybe<Scalars['Int']['input']>;
  productId: Scalars['ID']['input'];
  type: InputMaybe<ProductPriceType>;
};

export type GetOrSetProductPriceCommandOutput = {
  __typename?: 'GetOrSetProductPriceCommandOutput';
  id: Maybe<Scalars['ID']['output']>;
};

export type GetOrderByIdQueryOutput = {
  __typename?: 'GetOrderByIdQueryOutput';
  order: Maybe<GetOrdersQueryOutputOrder>;
};

export type GetOrderForProductTypeByIdQueryOutput = {
  __typename?: 'GetOrderForProductTypeByIdQueryOutput';
  order: Maybe<GetOrderForProductTypeQueryOutputOrder>;
};

export type GetOrderForProductTypeQueryOutputOrder = {
  __typename?: 'GetOrderForProductTypeQueryOutputOrder';
  completedDateTime: Maybe<Scalars['String']['output']>;
  createdDateTime: Maybe<Scalars['String']['output']>;
  customer: Maybe<GetOrdersQueryOutputCustomer>;
  customerAddress: Maybe<GetOrdersQueryOutputCustomerAddress>;
  id: Maybe<Scalars['ID']['output']>;
  index: Maybe<Scalars['Float']['output']>;
  lineItems: Maybe<Array<Maybe<GetOrdersQueryOutputOrderLineItem>>>;
  orderFulfilmentStatus: Maybe<EntityReferenceDto>;
  orderNumber: Maybe<Scalars['String']['output']>;
  orderPayStatus: Maybe<EntityReferenceDto>;
  organisation: Maybe<EntityReferenceDto>;
  refundDateTime: Maybe<Scalars['String']['output']>;
  totalCost: Maybe<Scalars['Int']['output']>;
  totalRefunded: Maybe<Scalars['Int']['output']>;
  updatedDateTime: Maybe<Scalars['String']['output']>;
};

export type GetOrderFulfilmentStatusValuesQueryOutput = {
  __typename?: 'GetOrderFulfilmentStatusValuesQueryOutput';
  orderFulfilmentStatus: Maybe<Array<Maybe<EntityReferenceDto>>>;
};

export type GetOrderLineItemDetailsForProductTypeQueryOutput = {
  __typename?: 'GetOrderLineItemDetailsForProductTypeQueryOutput';
  canBeCancelled: Maybe<Scalars['Boolean']['output']>;
  canBeRefunded: Maybe<Scalars['Boolean']['output']>;
  lineItemDetails: Maybe<Array<Maybe<GetOrderLineItemDetailsForProductTypeQueryOutputLineItemDetail>>>;
  total: Maybe<Scalars['Int']['output']>;
};

export type GetOrderLineItemDetailsForProductTypeQueryOutputLineItemDetail = {
  __typename?: 'GetOrderLineItemDetailsForProductTypeQueryOutputLineItemDetail';
  canAllBeCancelled: Maybe<Scalars['Boolean']['output']>;
  canAllBeCancelledResult: Maybe<CanBeCancelledQueryOutput>;
  canAllBeRefunded: Maybe<Scalars['Boolean']['output']>;
  canAllBeRefundedResult: Maybe<CanBeRefundedQueryOutput>;
  canBeCancelled: Maybe<Scalars['Boolean']['output']>;
  canBeCancelledResult: Maybe<CanBeCancelledQueryOutput>;
  canBeRefunded: Maybe<Scalars['Boolean']['output']>;
  canBeRefundedResult: Maybe<CanBeRefundedQueryOutput>;
  lineItem: Maybe<GetOrdersQueryOutputOrderLineItem>;
  quantity: Maybe<Scalars['Int']['output']>;
  subTotal: Maybe<Scalars['Int']['output']>;
};

export type GetOrderPayStatusValuesQueryOutput = {
  __typename?: 'GetOrderPayStatusValuesQueryOutput';
  orderPayStatus: Maybe<Array<Maybe<EntityReferenceDto>>>;
};

export type GetOrderProductsQueryOutput = {
  __typename?: 'GetOrderProductsQueryOutput';
  products: Maybe<Array<Maybe<GetOrderProductsQueryOutputProduct>>>;
};

export type GetOrderProductsQueryOutputProduct = {
  __typename?: 'GetOrderProductsQueryOutputProduct';
  name: Maybe<Scalars['String']['output']>;
  productId: Maybe<Scalars['ID']['output']>;
};

export type GetOrdersForProductTypeQueryOutput = {
  __typename?: 'GetOrdersForProductTypeQueryOutput';
  orders: Maybe<Array<Maybe<GetOrderForProductTypeQueryOutputOrder>>>;
  pagedListMetaData: Maybe<IPagedList>;
};

export type GetOrdersQueryOutput = {
  __typename?: 'GetOrdersQueryOutput';
  orders: Maybe<Array<Maybe<GetOrdersQueryOutputOrder>>>;
  pagedListMetaData: Maybe<IPagedList>;
};

export type GetOrdersQueryOutputAccount = {
  __typename?: 'GetOrdersQueryOutputAccount';
  id: Maybe<Scalars['ID']['output']>;
  isoCurrency: Maybe<GetOrdersQueryOutputIsoCurrency>;
  name: Maybe<Scalars['String']['output']>;
};

export type GetOrdersQueryOutputCustomer = {
  __typename?: 'GetOrdersQueryOutputCustomer';
  classicUserId: Maybe<Scalars['ID']['output']>;
  classicVenueContactId: Maybe<Scalars['ID']['output']>;
  email: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

export type GetOrdersQueryOutputCustomerAddress = {
  __typename?: 'GetOrdersQueryOutputCustomerAddress';
  address1: Maybe<Scalars['String']['output']>;
  address2: Maybe<Scalars['String']['output']>;
  address3: Maybe<Scalars['String']['output']>;
  county: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  postcode: Maybe<Scalars['String']['output']>;
  town: Maybe<Scalars['String']['output']>;
};

export type GetOrdersQueryOutputIsoCurrency = {
  __typename?: 'GetOrdersQueryOutputIsoCurrency';
  code: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

export type GetOrdersQueryOutputOrder = {
  __typename?: 'GetOrdersQueryOutputOrder';
  completedDateTime: Maybe<Scalars['String']['output']>;
  createdDateTime: Maybe<Scalars['String']['output']>;
  customer: Maybe<GetOrdersQueryOutputCustomer>;
  customerAddress: Maybe<GetOrdersQueryOutputCustomerAddress>;
  id: Maybe<Scalars['ID']['output']>;
  index: Maybe<Scalars['Float']['output']>;
  lineItems: Maybe<Array<Maybe<GetOrdersQueryOutputOrderLineItem>>>;
  orderFulfilmentStatus: Maybe<EntityReferenceDto>;
  orderNumber: Maybe<Scalars['String']['output']>;
  orderPayStatus: Maybe<EntityReferenceDto>;
  organisation: Maybe<EntityReferenceDto>;
  refundDateTime: Maybe<Scalars['String']['output']>;
  totalCost: Maybe<Scalars['Int']['output']>;
  totalNetCost: Maybe<Scalars['Int']['output']>;
  totalRefunded: Maybe<Scalars['Int']['output']>;
  transactions: Maybe<Array<Maybe<GetOrdersQueryOutputTransaction>>>;
  updatedDateTime: Maybe<Scalars['String']['output']>;
};

export type GetOrdersQueryOutputOrderLineItem = {
  __typename?: 'GetOrdersQueryOutputOrderLineItem';
  fulfilmentProviderInventoryTransactionId: Maybe<Scalars['ID']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  metadataValues: Maybe<Array<Maybe<GetOrdersQueryOutputOrderMetadataValue>>>;
  orderPayStatus: Maybe<EntityReferenceDto>;
  orderRefunds: Maybe<Array<Maybe<GetOrdersQueryOutputOrderRefund>>>;
  paymentMethod: Maybe<EntityReferenceDto>;
  price: Maybe<Scalars['Int']['output']>;
  product: Maybe<GetOrdersQueryOutputOrderProduct>;
  qty: Maybe<Scalars['Int']['output']>;
  sku: Maybe<Scalars['String']['output']>;
};

export type GetOrdersQueryOutputOrderMetadataValue = {
  __typename?: 'GetOrdersQueryOutputOrderMetadataValue';
  id: Maybe<Scalars['ID']['output']>;
  isDeleted: Maybe<Scalars['Boolean']['output']>;
  key: Maybe<Scalars['String']['output']>;
  orderLineItemId: Maybe<Scalars['ID']['output']>;
  value: Maybe<Scalars['String']['output']>;
};

export type GetOrdersQueryOutputOrderProduct = {
  __typename?: 'GetOrdersQueryOutputOrderProduct';
  files: Maybe<Array<Maybe<GetOrdersQueryOutputOrderProductFile>>>;
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  properties: Maybe<Array<Maybe<GetOrdersQueryOutputOrderProductProperty>>>;
  type: Maybe<EntityReferenceDto>;
  variantGroupId: Maybe<Scalars['ID']['output']>;
};

export type GetOrdersQueryOutputOrderProductFile = {
  __typename?: 'GetOrdersQueryOutputOrderProductFile';
  name: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type GetOrdersQueryOutputOrderProductProperty = {
  __typename?: 'GetOrdersQueryOutputOrderProductProperty';
  property: Maybe<Scalars['String']['output']>;
  propertyId: Maybe<Scalars['ID']['output']>;
  propertySortIndex: Maybe<Scalars['Int']['output']>;
  propertyValue: Maybe<Scalars['String']['output']>;
  propertyValueId: Maybe<Scalars['ID']['output']>;
  propertyValueSortIndex: Maybe<Scalars['Int']['output']>;
};

export type GetOrdersQueryOutputOrderRefund = {
  __typename?: 'GetOrdersQueryOutputOrderRefund';
  amount: Maybe<Scalars['Int']['output']>;
  createdDateTime: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  quantity: Maybe<Scalars['Int']['output']>;
  refundType: Maybe<RefundType>;
};

export type GetOrdersQueryOutputTransaction = {
  __typename?: 'GetOrdersQueryOutputTransaction';
  account: Maybe<GetOrdersQueryOutputAccount>;
  createdDateTime: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  isManualTransaction: Maybe<Scalars['Boolean']['output']>;
  orderPayStatus: Maybe<EntityReferenceDto>;
  payDate: Maybe<Scalars['String']['output']>;
  paymentMethod: Maybe<EntityReferenceDto>;
  sourceType: Maybe<EntityReferenceDto>;
  totalCost: Maybe<Scalars['Int']['output']>;
  totalDiscount: Maybe<Scalars['Int']['output']>;
  totalNetCost: Maybe<Scalars['Int']['output']>;
  totalPaymentProviderFeeAmount: Maybe<Scalars['Int']['output']>;
  totalPlatformFeeAmount: Maybe<Scalars['Int']['output']>;
  totalRefunded: Maybe<Scalars['Int']['output']>;
  transactionType: Maybe<EntityReferenceDto>;
};

export type GetOrdersWithProductSubscriptionsQueryBasketPayload = {
  __typename?: 'GetOrdersWithProductSubscriptionsQueryBasketPayload';
  classicUserId: Maybe<Scalars['ID']['output']>;
  createdDateTime: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  idempotencyKey: Maybe<Scalars['ID']['output']>;
  isProcessed: Maybe<Scalars['Boolean']['output']>;
  payload: Maybe<Scalars['String']['output']>;
};

export type GetOrdersWithProductSubscriptionsQueryCustomer = {
  __typename?: 'GetOrdersWithProductSubscriptionsQueryCustomer';
  classicUserId: Maybe<Scalars['ID']['output']>;
  email: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  mobile: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  organisationId: Maybe<Scalars['ID']['output']>;
  telephone: Maybe<Scalars['String']['output']>;
};

export type GetOrdersWithProductSubscriptionsQueryCustomerAddress = {
  __typename?: 'GetOrdersWithProductSubscriptionsQueryCustomerAddress';
  address1: Maybe<Scalars['String']['output']>;
  address2: Maybe<Scalars['String']['output']>;
  address3: Maybe<Scalars['String']['output']>;
  country: Maybe<Scalars['String']['output']>;
  county: Maybe<Scalars['String']['output']>;
  postcode: Maybe<Scalars['String']['output']>;
  town: Maybe<Scalars['String']['output']>;
};

export type GetOrdersWithProductSubscriptionsQueryLineItemMetadataValue = {
  __typename?: 'GetOrdersWithProductSubscriptionsQueryLineItemMetadataValue';
  key: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['String']['output']>;
};

export type GetOrdersWithProductSubscriptionsQueryLineItems = {
  __typename?: 'GetOrdersWithProductSubscriptionsQueryLineItems';
  id: Maybe<Scalars['ID']['output']>;
  isReorder: Maybe<Scalars['Boolean']['output']>;
  metadataValues: Maybe<Array<Maybe<GetOrdersWithProductSubscriptionsQueryLineItemMetadataValue>>>;
  orderId: Maybe<Scalars['ID']['output']>;
  orderMetadata: Maybe<Scalars['String']['output']>;
  orderPayStatus: Maybe<EntityReferenceDto>;
  payDate: Maybe<Scalars['String']['output']>;
  paySource: Maybe<Scalars['String']['output']>;
  paymentMethod: Maybe<EntityReferenceDto>;
  price: Maybe<Scalars['Int']['output']>;
  product: Maybe<GetOrdersWithProductSubscriptionsQueryProduct>;
  qty: Maybe<Scalars['Int']['output']>;
  sku: Maybe<Scalars['String']['output']>;
};

export type GetOrdersWithProductSubscriptionsQueryOrder = {
  __typename?: 'GetOrdersWithProductSubscriptionsQueryOrder';
  basketPayload: Maybe<GetOrdersWithProductSubscriptionsQueryBasketPayload>;
  completedDateTime: Maybe<Scalars['String']['output']>;
  createdDateTime: Maybe<Scalars['String']['output']>;
  customer: Maybe<GetOrdersWithProductSubscriptionsQueryCustomer>;
  customerAddress: Maybe<GetOrdersWithProductSubscriptionsQueryCustomerAddress>;
  customerId: Maybe<Scalars['ID']['output']>;
  hasMoreTransactions: Maybe<Scalars['Boolean']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  index: Maybe<Scalars['Float']['output']>;
  lineItems: Maybe<Array<Maybe<GetOrdersWithProductSubscriptionsQueryLineItems>>>;
  orderAttributes: Maybe<Scalars['String']['output']>;
  orderFulfilmentStatus: Maybe<EntityReferenceDto>;
  orderNumber: Maybe<Scalars['String']['output']>;
  orderPayStatus: Maybe<EntityReferenceDto>;
  organisationId: Maybe<Scalars['ID']['output']>;
  productSubscriptions: Maybe<Array<Maybe<GetOrdersWithProductSubscriptionsQueryProductSubscription>>>;
  refundDateTime: Maybe<Scalars['String']['output']>;
  totalCost: Maybe<Scalars['Int']['output']>;
  totalNetCost: Maybe<Scalars['Int']['output']>;
  totalRefunded: Maybe<Scalars['Int']['output']>;
  transactions: Maybe<Array<Maybe<GetOrdersWithProductSubscriptionsQueryTransaction>>>;
};

export type GetOrdersWithProductSubscriptionsQueryOrderLineItemTransaction = {
  __typename?: 'GetOrdersWithProductSubscriptionsQueryOrderLineItemTransaction';
  amountPaid: Maybe<Scalars['Int']['output']>;
  createdDateTime: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  orderLineItemId: Maybe<Scalars['ID']['output']>;
  productSubscriptionInvoiceId: Maybe<Scalars['ID']['output']>;
  updatedDateTime: Maybe<Scalars['String']['output']>;
};

export type GetOrdersWithProductSubscriptionsQueryOutput = {
  __typename?: 'GetOrdersWithProductSubscriptionsQueryOutput';
  orders: Maybe<Array<Maybe<GetOrdersWithProductSubscriptionsQueryOrder>>>;
};

export type GetOrdersWithProductSubscriptionsQueryProduct = {
  __typename?: 'GetOrdersWithProductSubscriptionsQueryProduct';
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  type: Maybe<EntityReferenceDto>;
};

export type GetOrdersWithProductSubscriptionsQueryProductSubscription = {
  __typename?: 'GetOrdersWithProductSubscriptionsQueryProductSubscription';
  accountId: Maybe<Scalars['ID']['output']>;
  cancelledDateTime: Maybe<Scalars['String']['output']>;
  createdDateTime: Maybe<Scalars['String']['output']>;
  details: Maybe<GetOrdersWithProductSubscriptionsQueryProductSubscriptionDetails>;
  endAt: Maybe<Scalars['String']['output']>;
  expired: Maybe<Scalars['Boolean']['output']>;
  externalId: Maybe<Scalars['String']['output']>;
  fullCost: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  installmentCost: Maybe<Scalars['Int']['output']>;
  invoices: Maybe<Array<Maybe<GetOrdersWithProductSubscriptionsQueryProductSubscriptionInvoice>>>;
  numberOfInstallments: Maybe<Scalars['Int']['output']>;
  orderId: Maybe<Scalars['ID']['output']>;
  orderLineItemId: Maybe<Scalars['ID']['output']>;
  pausedDateTime: Maybe<Scalars['String']['output']>;
  productId: Maybe<Scalars['ID']['output']>;
  startAt: Maybe<Scalars['String']['output']>;
  totalPaid: Maybe<Scalars['Int']['output']>;
  totalRefunded: Maybe<Scalars['Int']['output']>;
  updatedDateTime: Maybe<Scalars['String']['output']>;
};

export type GetOrdersWithProductSubscriptionsQueryProductSubscriptionDetails = {
  __typename?: 'GetOrdersWithProductSubscriptionsQueryProductSubscriptionDetails';
  goCardless: Maybe<GetOrdersWithProductSubscriptionsQueryProductSubscriptionDetailsGoCardless>;
};

export type GetOrdersWithProductSubscriptionsQueryProductSubscriptionDetailsGoCardless = {
  __typename?: 'GetOrdersWithProductSubscriptionsQueryProductSubscriptionDetailsGoCardless';
  mandateId: Maybe<Scalars['String']['output']>;
};

export type GetOrdersWithProductSubscriptionsQueryProductSubscriptionInvoice = {
  __typename?: 'GetOrdersWithProductSubscriptionsQueryProductSubscriptionInvoice';
  actualTotalPrice: Maybe<Scalars['Int']['output']>;
  actualTotalPriceOutstanding: Maybe<Scalars['Int']['output']>;
  actualTotalPricePaid: Maybe<Scalars['Int']['output']>;
  actualTotalPriceRefunded: Maybe<Scalars['Int']['output']>;
  amount: Maybe<Scalars['Int']['output']>;
  createdDateTime: Maybe<Scalars['String']['output']>;
  externalId: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  metaData: Maybe<Scalars['String']['output']>;
  payDate: Maybe<Scalars['String']['output']>;
  productSubscriptionId: Maybe<Scalars['ID']['output']>;
  updatedDateTime: Maybe<Scalars['String']['output']>;
};

export type GetOrdersWithProductSubscriptionsQueryTransaction = {
  __typename?: 'GetOrdersWithProductSubscriptionsQueryTransaction';
  accountId: Maybe<Scalars['ID']['output']>;
  createdDateTime: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  details: Maybe<GetOrdersWithProductSubscriptionsQueryTransactionDetails>;
  externalId: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  isManualTransaction: Maybe<Scalars['Boolean']['output']>;
  isNotForOrder: Maybe<Scalars['Boolean']['output']>;
  isoCurrency: Maybe<Scalars['String']['output']>;
  orderLineItemTransactions: Maybe<Array<Maybe<GetOrdersWithProductSubscriptionsQueryOrderLineItemTransaction>>>;
  orderPayStatus: Maybe<EntityReferenceDto>;
  parentTransactionId: Maybe<Scalars['ID']['output']>;
  payDate: Maybe<Scalars['String']['output']>;
  payloads: Maybe<Array<Maybe<GetOrdersWithProductSubscriptionsQueryTransactionPayload>>>;
  paymentMethod: Maybe<EntityReferenceDto>;
  sourceType: Maybe<EntityReferenceDto>;
  totalAmount: Maybe<Scalars['Int']['output']>;
  totalCost: Maybe<Scalars['Int']['output']>;
  totalDiscount: Maybe<Scalars['Int']['output']>;
  totalNetCost: Maybe<Scalars['Int']['output']>;
  totalPaymentProviderFeeAmount: Maybe<Scalars['Int']['output']>;
  totalPlatformFeeAmount: Maybe<Scalars['Int']['output']>;
  totalRefunded: Maybe<Scalars['Int']['output']>;
  transactionType: Maybe<EntityReferenceDto>;
};

export type GetOrdersWithProductSubscriptionsQueryTransactionDetails = {
  __typename?: 'GetOrdersWithProductSubscriptionsQueryTransactionDetails';
  goCardlessPayment: Maybe<GetOrdersWithProductSubscriptionsQueryTransactionDetailsGoCardlessPayment>;
  stripeCheckout: Maybe<GetOrdersWithProductSubscriptionsQueryTransactionDetailsStripeCheckout>;
  stripeInvoice: Maybe<GetOrdersWithProductSubscriptionsQueryTransactionDetailsStripeInvoice>;
};

export type GetOrdersWithProductSubscriptionsQueryTransactionDetailsGoCardlessPayment = {
  __typename?: 'GetOrdersWithProductSubscriptionsQueryTransactionDetailsGoCardlessPayment';
  chargeDate: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  mandateId: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
};

export type GetOrdersWithProductSubscriptionsQueryTransactionDetailsStripeCheckout = {
  __typename?: 'GetOrdersWithProductSubscriptionsQueryTransactionDetailsStripeCheckout';
  completed: Maybe<Scalars['Boolean']['output']>;
};

export type GetOrdersWithProductSubscriptionsQueryTransactionDetailsStripeInvoice = {
  __typename?: 'GetOrdersWithProductSubscriptionsQueryTransactionDetailsStripeInvoice';
  chargeOutcomeDescription: Maybe<Scalars['String']['output']>;
  created: Maybe<Scalars['Int']['output']>;
  id: Maybe<Scalars['String']['output']>;
  paymentIntentId: Maybe<Scalars['String']['output']>;
  paymentIntentStatus: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
};

export type GetOrdersWithProductSubscriptionsQueryTransactionPayload = {
  __typename?: 'GetOrdersWithProductSubscriptionsQueryTransactionPayload';
  createdDateTime: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  isGraphQlCall: Maybe<Scalars['Boolean']['output']>;
  isRequest: Maybe<Scalars['Boolean']['output']>;
  isWebhookCall: Maybe<Scalars['Boolean']['output']>;
  payload: Maybe<Scalars['String']['output']>;
  transactionId: Maybe<Scalars['ID']['output']>;
  webhookObject: Maybe<Scalars['String']['output']>;
};

export type GetParkSite = {
  __typename?: 'GetParkSite';
  countyName: Maybe<Scalars['String']['output']>;
  freeOrPaid: Maybe<Scalars['String']['output']>;
  latitude: Maybe<Scalars['String']['output']>;
  localAuthority: Maybe<Scalars['String']['output']>;
  longitude: Maybe<Scalars['String']['output']>;
  mailingCity: Maybe<Scalars['String']['output']>;
  mailingCountry: Maybe<Scalars['String']['output']>;
  mailingPostalCode: Maybe<Scalars['String']['output']>;
  mailingStreet: Maybe<Scalars['String']['output']>;
  mailingStreet2: Maybe<Scalars['String']['output']>;
  mailingStreet3: Maybe<Scalars['String']['output']>;
  parkSiteId: Maybe<Scalars['String']['output']>;
  venueName: Maybe<Scalars['String']['output']>;
};

export type GetPaymentMethodManagementUrlCurrentUserQueryOutput = {
  __typename?: 'GetPaymentMethodManagementUrlCurrentUserQueryOutput';
  url: Maybe<Scalars['String']['output']>;
};

export type GetPaymentMethodsQueryOutput = {
  __typename?: 'GetPaymentMethodsQueryOutput';
  paymentMethods: Maybe<Array<Maybe<GetPaymentMethodsQueryOutputPaymentMethod>>>;
};

export type GetPaymentMethodsQueryOutputPaymentMethod = {
  __typename?: 'GetPaymentMethodsQueryOutputPaymentMethod';
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  paymentProvider: Maybe<EntityReferenceDto>;
};

export type GetProRataAmountForProductQueryOutput = {
  __typename?: 'GetProRataAmountForProductQueryOutput';
  amount: Maybe<Scalars['Int']['output']>;
  subscriptionStartDate: Maybe<Scalars['String']['output']>;
};

export type GetProductByIdQueryOutput = {
  __typename?: 'GetProductByIdQueryOutput';
  product: Maybe<ProductDto>;
};

export type GetProductsByStatusQueryOutput = {
  __typename?: 'GetProductsByStatusQueryOutput';
  products: Maybe<Array<Maybe<GetProductsByStatusQueryProduct>>>;
};

export type GetProductsByStatusQueryProduct = {
  __typename?: 'GetProductsByStatusQueryProduct';
  availableWith: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  isoCurrencyCode: Maybe<Scalars['String']['output']>;
  price: Maybe<Scalars['Int']['output']>;
  productId: Maybe<Scalars['ID']['output']>;
  productName: Maybe<Scalars['String']['output']>;
  productSku: Maybe<Scalars['String']['output']>;
  productStatus: Maybe<ProductStatus>;
  revenue: Maybe<Scalars['Int']['output']>;
  totalSold: Maybe<Scalars['Int']['output']>;
  variants: Maybe<Scalars['String']['output']>;
};

export type GetProductsByTypeQueryOutput = {
  __typename?: 'GetProductsByTypeQueryOutput';
  pagedListMetaData: Maybe<IPagedList>;
  products: Maybe<Array<Maybe<GetProductsByTypeQueryProduct>>>;
};

export type GetProductsByTypeQueryProduct = {
  __typename?: 'GetProductsByTypeQueryProduct';
  productId: Maybe<Scalars['ID']['output']>;
  productName: Maybe<Scalars['String']['output']>;
};

export type GetProgramsForOrg = {
  __typename?: 'GetProgramsForOrg';
  facilities: Maybe<Array<GetProgramsForOrgFacility>>;
  subscriptions: Maybe<Array<GetProgramsForOrgSubscription>>;
};

export type GetProgramsForOrgFacility = {
  __typename?: 'GetProgramsForOrgFacility';
  facilityName: Maybe<Scalars['String']['output']>;
  facilityType: Maybe<Scalars['String']['output']>;
  summerSession: Maybe<Scalars['Boolean']['output']>;
  winterSession: Maybe<Scalars['Boolean']['output']>;
};

export type GetProgramsForOrgSubscription = {
  __typename?: 'GetProgramsForOrgSubscription';
  numberofMen: Maybe<Scalars['Int']['output']>;
  numberofWomen: Maybe<Scalars['Int']['output']>;
  programmeCategory: Maybe<Scalars['String']['output']>;
};

export type GetStripeCheckoutSessionToAddPaymentMethodForCurrentUserQueryInput = {
  cancelUrl: Scalars['String']['input'];
  productId: InputMaybe<Scalars['ID']['input']>;
  successUrl: Scalars['String']['input'];
};

export type GetStripeCheckoutSessionToAddPaymentMethodQueryOutput = {
  __typename?: 'GetStripeCheckoutSessionToAddPaymentMethodQueryOutput';
  checkoutPageUrl: Maybe<Scalars['String']['output']>;
  stripeCheckoutSessionId: Maybe<Scalars['String']['output']>;
  stripePublicKey: Maybe<Scalars['String']['output']>;
};

export type GetTransactionsByProductTypeForOrderQueryOutput = {
  __typename?: 'GetTransactionsByProductTypeForOrderQueryOutput';
  outstandingBalance: Maybe<Scalars['Int']['output']>;
  results: Maybe<Array<Maybe<GetTransactionsByProductTypeForOrderQueryOutputResult>>>;
  showPayButton: Maybe<Scalars['Boolean']['output']>;
  total: Maybe<Scalars['Int']['output']>;
};

export type GetTransactionsByProductTypeForOrderQueryOutputResult = {
  __typename?: 'GetTransactionsByProductTypeForOrderQueryOutputResult';
  amount: Maybe<Scalars['Int']['output']>;
  canCancelledOrderBeRefundedResult: Maybe<CanBeRefundedQueryOutput>;
  createdDateTime: Maybe<Scalars['String']['output']>;
  entityType: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  isPaid: Maybe<Scalars['Boolean']['output']>;
  payDate: Maybe<Scalars['String']['output']>;
  paymentMethod: Maybe<Scalars['String']['output']>;
  resultType: Maybe<GetTransactionsByProductTypeForOrderQueryOutputResultType>;
  resultTypeDescription: Maybe<Scalars['String']['output']>;
  showRefundCancelledOrderButton: Maybe<Scalars['Boolean']['output']>;
  source: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
};

export enum GetTransactionsByProductTypeForOrderQueryOutputResultType {
  CANCELLEDITEMS = 'CANCELLEDITEMS',
  CHARGE = 'CHARGE',
  REFUND = 'REFUND',
}

export type GetValidationFlags = {
  __typename?: 'GetValidationFlags';
  coachAccreditationStandardsFlag: Maybe<Scalars['Boolean']['output']>;
  organisationName: Maybe<Scalars['String']['output']>;
  salesforceID: Maybe<Scalars['String']['output']>;
  welfareStandardsFlag: Maybe<Scalars['Boolean']['output']>;
};

export type GoCardlessPayment = {
  __typename?: 'GoCardlessPayment';
  amount: Maybe<Scalars['Int']['output']>;
  amountRefunded: Maybe<Scalars['Int']['output']>;
  chargeDate: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['String']['output']>;
  currency: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  fx: Maybe<GoCardlessPaymentFx>;
  id: Maybe<Scalars['String']['output']>;
  links: Maybe<GoCardlessPaymentLinks>;
  metadata: Maybe<Scalars['JSON']['output']>;
  reference: Maybe<Scalars['String']['output']>;
  retryIfPossible: Maybe<Scalars['Boolean']['output']>;
  status: Maybe<GoCardlessPaymentStatus>;
};

export type GoCardlessPaymentFx = {
  __typename?: 'GoCardlessPaymentFx';
  estimatedExchangeRate: Maybe<Scalars['String']['output']>;
  exchangeRate: Maybe<Scalars['String']['output']>;
  fxAmount: Maybe<Scalars['Int']['output']>;
  fxCurrency: Maybe<Scalars['String']['output']>;
};

export type GoCardlessPaymentLinks = {
  __typename?: 'GoCardlessPaymentLinks';
  creditor: Maybe<Scalars['String']['output']>;
  instalmentSchedule: Maybe<Scalars['String']['output']>;
  mandate: Maybe<Scalars['String']['output']>;
  payout: Maybe<Scalars['String']['output']>;
  subscription: Maybe<Scalars['String']['output']>;
};

export enum GoCardlessPaymentStatus {
  CANCELLED = 'CANCELLED',
  CHARGEDBACK = 'CHARGEDBACK',
  CONFIRMED = 'CONFIRMED',
  CUSTOMERAPPROVALDENIED = 'CUSTOMERAPPROVALDENIED',
  FAILED = 'FAILED',
  PAIDOUT = 'PAIDOUT',
  PENDINGCUSTOMERAPPROVAL = 'PENDINGCUSTOMERAPPROVAL',
  PENDINGSUBMISSION = 'PENDINGSUBMISSION',
  SUBMITTED = 'SUBMITTED',
  UNKNOWN = 'UNKNOWN',
}

export type GoCardlessSubscription = {
  __typename?: 'GoCardlessSubscription';
  amount: Maybe<Scalars['Int']['output']>;
  appFee: Maybe<Scalars['Int']['output']>;
  count: Maybe<Scalars['Int']['output']>;
  createdAt: Maybe<Scalars['String']['output']>;
  currency: Maybe<Scalars['String']['output']>;
  dayOfMonth: Maybe<Scalars['Int']['output']>;
  earliestChargeDateAfterResume: Maybe<Scalars['String']['output']>;
  endDate: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  interval: Maybe<Scalars['Int']['output']>;
  intervalUnit: Maybe<Scalars['String']['output']>;
  links: Maybe<GoCardlessSubscriptionLinks>;
  metadata: Maybe<Scalars['JSON']['output']>;
  month: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  paymentReference: Maybe<Scalars['String']['output']>;
  retryIfPossible: Maybe<Scalars['Boolean']['output']>;
  startDate: Maybe<Scalars['String']['output']>;
  status: Maybe<Scalars['String']['output']>;
  upcomingPayments: Maybe<Array<Maybe<GoCardlessSubscriptionUpcomingPayment>>>;
};

export type GoCardlessSubscriptionLinks = {
  __typename?: 'GoCardlessSubscriptionLinks';
  mandate: Maybe<Scalars['String']['output']>;
};

export type GoCardlessSubscriptionUpcomingPayment = {
  __typename?: 'GoCardlessSubscriptionUpcomingPayment';
  amount: Maybe<Scalars['Int']['output']>;
  chargeDate: Maybe<Scalars['String']['output']>;
};

export type IPagedList = {
  __typename?: 'IPagedList';
  firstItemOnPage: Maybe<Scalars['Int']['output']>;
  hasNextPage: Maybe<Scalars['Boolean']['output']>;
  hasPreviousPage: Maybe<Scalars['Boolean']['output']>;
  isFirstPage: Maybe<Scalars['Boolean']['output']>;
  isLastPage: Maybe<Scalars['Boolean']['output']>;
  lastItemOnPage: Maybe<Scalars['Int']['output']>;
  pageCount: Maybe<Scalars['Int']['output']>;
  pageNumber: Maybe<Scalars['Int']['output']>;
  pageSize: Maybe<Scalars['Int']['output']>;
  totalItemCount: Maybe<Scalars['Int']['output']>;
};

export type InviteEmailInput = {
  emailAddresses: Array<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
};

export type InvoiceAddress = {
  __typename?: 'InvoiceAddress';
  id: Scalars['String']['output'];
  invoiceAddress: InvoiceAddressFieldsType;
};

export type InvoiceAddressFieldsInput = {
  countyName: Scalars['String']['input'];
  mailingCity: Scalars['String']['input'];
  mailingCountry: Scalars['String']['input'];
  mailingPostalCode: Scalars['String']['input'];
  mailingStreet: Scalars['String']['input'];
  mailingStreet2: InputMaybe<Scalars['String']['input']>;
  mailingStreet3: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceAddressFieldsType = {
  __typename?: 'InvoiceAddressFieldsType';
  countyName: Scalars['String']['output'];
  mailingCity: Scalars['String']['output'];
  mailingCountry: Scalars['String']['output'];
  mailingPostalCode: Scalars['String']['output'];
  mailingStreet: Scalars['String']['output'];
  mailingStreet2: Maybe<Scalars['String']['output']>;
  mailingStreet3: Maybe<Scalars['String']['output']>;
};

export type InvoiceAddressInput = {
  id: InputMaybe<Scalars['String']['input']>;
  invoiceAddress: InputMaybe<InvoiceAddressFieldsInput>;
};

export type LTAMembersForOrganisation = {
  __typename?: 'LTAMembersForOrganisation';
  totalMembers: Scalars['Int']['output'];
};

export type LastYearOrganisationInformation = {
  __typename?: 'LastYearOrganisationInformation';
  membershipNumber: Scalars['String']['output'];
  name: Scalars['String']['output'];
  salesforceID: Scalars['String']['output'];
};

export type LastYearOrganisationInformationInput = {
  membershipNumber: Scalars['String']['input'];
  name: Scalars['String']['input'];
  salesforceID: Scalars['String']['input'];
};

/** ListOfAccount */
export type ListOfAccount = {
  __typename?: 'ListOfAccount';
  id: Maybe<Scalars['String']['output']>;
  isRegistered: Maybe<Scalars['Boolean']['output']>;
  membershipNumber: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  postCode: Maybe<Scalars['String']['output']>;
  sfid: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
};

export type MailingAddress = {
  __typename?: 'MailingAddress';
  countyName: Maybe<Scalars['String']['output']>;
  deliveryContact: Maybe<Scalars['String']['output']>;
  latitude: Maybe<Scalars['String']['output']>;
  localAuthority: Maybe<Scalars['String']['output']>;
  longitude: Maybe<Scalars['String']['output']>;
  mailingCity: Maybe<Scalars['String']['output']>;
  mailingCountry: Maybe<Scalars['String']['output']>;
  mailingPostalCode: Maybe<Scalars['String']['output']>;
  mailingStreet: Maybe<Scalars['String']['output']>;
  mailingStreet2: Maybe<Scalars['String']['output']>;
  mailingStreet3: Maybe<Scalars['String']['output']>;
};

/** Member */
export type Member = {
  __typename?: 'Member';
  addedDate: Scalars['DateTime']['output'];
  courtCount: Maybe<Scalars['Int']['output']>;
  formSubmittedDate: Scalars['DateTime']['output'];
  grassCourtCount: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isNew: Scalars['Boolean']['output'];
  membershipPackageID: Scalars['ID']['output'];
  name: Maybe<Scalars['String']['output']>;
  orderID: Maybe<Scalars['String']['output']>;
  packageYear: Scalars['Float']['output'];
  paymentDate: Scalars['DateTime']['output'];
  paymentMethod: PaymentMethod;
  paymentReference: Maybe<Scalars['String']['output']>;
  paymentStatus: PaymentStatus;
  salesforceID: Scalars['ID']['output'];
  status: OrganisationStatus;
  totalCost: Maybe<Scalars['Float']['output']>;
};

/** Member input */
export type MemberInput = {
  addedDate: InputMaybe<Scalars['DateTime']['input']>;
  courtCount: InputMaybe<Scalars['Int']['input']>;
  formSubmittedDate: InputMaybe<Scalars['DateTime']['input']>;
  grassCourtCount: InputMaybe<Scalars['Int']['input']>;
  isNew: InputMaybe<Scalars['Boolean']['input']>;
  membershipPackageID: InputMaybe<Scalars['ID']['input']>;
  orderID: InputMaybe<Scalars['String']['input']>;
  packageYear: InputMaybe<Scalars['Float']['input']>;
  paymentDate: InputMaybe<Scalars['DateTime']['input']>;
  paymentMethod: InputMaybe<PaymentMethod>;
  paymentReference: InputMaybe<Scalars['String']['input']>;
  paymentStatus: InputMaybe<PaymentStatus>;
  salesforceID: InputMaybe<Scalars['ID']['input']>;
  status: InputMaybe<OrganisationStatus>;
  totalCost: InputMaybe<Scalars['Float']['input']>;
};

/** Membership Package */
export type MembershipPackage = {
  __typename?: 'MembershipPackage';
  benefits: Scalars['String']['output'];
  code: Scalars['String']['output'];
  confirmation: Scalars['String']['output'];
  courtCap: Maybe<Scalars['Int']['output']>;
  courtCost: Maybe<Scalars['Float']['output']>;
  description: Scalars['String']['output'];
  endDate: Scalars['DateTime']['output'];
  gracePeriodDate: Scalars['DateTime']['output'];
  grassCourtCost: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  insurancePackageID: Maybe<Scalars['ID']['output']>;
  isInsurancePackage: Maybe<Scalars['Boolean']['output']>;
  maxPrice: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  organisationType: Scalars['String']['output'];
  packageYear: Scalars['Float']['output'];
  paymentMethodAvailable: Array<PaymentMethodAvailable>;
  pricingOption: PricingOption;
  processingFee: Maybe<Scalars['Float']['output']>;
  processingFeeType: Maybe<ProcessingFeeType>;
  productID: Scalars['String']['output'];
  renewalPeriod: MembershipPackageRenewalPeriod;
  renewsOn: Scalars['DateTime']['output'];
  startDate: Scalars['DateTime']['output'];
  status: MembershipPackageStatus;
  type: Scalars['String']['output'];
};

/** Membership Package Input */
export type MembershipPackageInput = {
  benefits: InputMaybe<Scalars['String']['input']>;
  code: InputMaybe<Scalars['String']['input']>;
  confirmation: InputMaybe<Scalars['String']['input']>;
  courtCap: InputMaybe<Scalars['Int']['input']>;
  courtCost: InputMaybe<Scalars['Float']['input']>;
  description: InputMaybe<Scalars['String']['input']>;
  endDate: InputMaybe<Scalars['DateTime']['input']>;
  gracePeriodDate: InputMaybe<Scalars['DateTime']['input']>;
  grassCourtCost: InputMaybe<Scalars['Float']['input']>;
  insurancePackageID: InputMaybe<Scalars['ID']['input']>;
  isInsurancePackage: InputMaybe<Scalars['Boolean']['input']>;
  maxPrice: InputMaybe<Scalars['Float']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  organisationType: InputMaybe<Scalars['String']['input']>;
  packageYear: InputMaybe<Scalars['Float']['input']>;
  paymentMethodAvailable: InputMaybe<Array<PaymentMethodAvailable>>;
  pricingOption: InputMaybe<PricingOption>;
  processingFee: InputMaybe<Scalars['Float']['input']>;
  processingFeeType: InputMaybe<ProcessingFeeType>;
  renewalPeriod: InputMaybe<MembershipPackageRenewalPeriod>;
  renewsOn: InputMaybe<Scalars['DateTime']['input']>;
  startDate: InputMaybe<Scalars['DateTime']['input']>;
  status: InputMaybe<MembershipPackageStatus>;
  type: InputMaybe<Scalars['String']['input']>;
};

/** Membership packages a member purchased */
export type MembershipPackageMember = {
  __typename?: 'MembershipPackageMember';
  member: Maybe<Member>;
  membershipPackage: Maybe<MembershipPackage>;
};

export enum MembershipPackageRenewalPeriod {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY',
}

export enum MembershipPackageStatus {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

/** Membership Package */
export type MembershipPackageYear = {
  __typename?: 'MembershipPackageYear';
  packageYear: Scalars['Float']['output'];
};

/** Membership Package */
export type MembershipPackageYearInput = {
  packageYear: Scalars['Float']['input'];
};

export type Message = {
  __typename?: 'Message';
  message: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Add an invoice address */
  addInvoiceAddress: InvoiceAddress;
  addLastYearOrganisationInformation: LastYearOrganisationInformation;
  /** Add a member */
  addMember: Member;
  /** Add a membership package */
  addMembershipPackage: MembershipPackage;
  /** Add a PO number */
  addPoNumber: PoNumber;
  /** Add a registration progress object for a an organisation */
  addRegistrationProgress: RegistrationProgress;
  /** Record a Stripe transaction */
  addcheckoutSession: CheckoutSession;
  /** Associate many park coaching operators */
  associateManyParkCoachingOperators: Message;
  /** Associate many park court operators */
  associateManyParkCourtOperators: Message;
  /** Associate park coaching operator */
  associateParkCoachingOperator: Message;
  /** Associate park court operator */
  associateParkCourtOperator: Message;
  /** associateParkOperator */
  associateParkOperator: Message;
  /** A6.2 associateParkSite */
  associateParkSite: Message;
  /**
   *
   *
   * Equivalent to POST /v1/Basket/CheckoutAutoPayment
   */
  basketPOSTCheckoutAutoPaymentBasket: Maybe<CheckoutBasketCommandOutput>;
  /**
   *
   *
   * Equivalent to POST /v1/Basket/Checkout
   */
  basketPOSTCheckoutBasket: Maybe<CheckoutBasketCommandOutput>;
  /** Starts the checkout process */
  createCheckoutSession: CheckoutSessionResponse;
  /** Create a Stripe checkout session */
  createCheckoutSession_old: CheckoutSessionID;
  /** A5.7 createContact */
  createContact: CreateContact;
  /** Delete an invoice address */
  deleteInvoiceAddress: InvoiceAddress;
  /** Delete a member */
  deleteMember: Member;
  /** Update a membership package */
  deleteMembershipPackage: MembershipPackage;
  /** Delete a PO number */
  deletePoNumber: PoNumber;
  /** Delete a registration progress object for a an organisation */
  deleteRegistrationProgress: RegistrationProgress;
  /**
   *
   *
   * Equivalent to POST /v1/FulfilmentProvider/AddFulfilmentProvider
   */
  fulfilmentProviderPOSTAddFulfilmentProvider: Maybe<Scalars['ID']['output']>;
  /**
   * GoCardlessHandleWebhook
   *
   * Equivalent to POST /v1/GoCardless/HandleWebhook
   */
  goCardlessPOSTHandleWebhook: Maybe<Scalars['JSON']['output']>;
  /**
   *
   *
   * Equivalent to POST /v1/GoCardless/SyncGoCardlessBillingRequest
   */
  goCardlessPOSTSyncGoCardlessBillingRequest: Maybe<Scalars['JSON']['output']>;
  /**
   * Sync GoCardless Payments For Product Subscriptions And Transactions
   *
   * Equivalent to POST /v1/GoCardless/SyncGoCardlessPaymentsForProductSubscriptionsAndTransactions
   */
  goCardlessPOSTSyncGoCardlessPaymentsForProductSubscriptionsAndTransactions: Maybe<Scalars['JSON']['output']>;
  /**
   *
   *
   * Equivalent to POST /v1/GoCardless/SyncGoCardlessSubscriptions
   */
  goCardlessPOSTSyncGoCardlessSubscriptions: Maybe<Scalars['JSON']['output']>;
  /** A5.6 inviteEmail */
  inviteEmail: Message;
  /** Marks a payment by invoice as paid for a specific member. */
  markInvoicePaymentAsPaidForMember: Member;
  /** A5.5 newVenueCreation */
  newVenueCreation: NewVenueCreation;
  /**
   *
   *
   * Equivalent to POST /v1/Order/CancelDuplicateAutoPaymentTransactions
   */
  orderPOSTCancelDuplicateAutoPaymentTransactions: Maybe<Scalars['JSON']['output']>;
  /**
   * CancelLineItemsByProductTypeForOrder
   *
   * Equivalent to POST /v1/Order/CancelLineItemsByProductTypeForOrder
   */
  orderPOSTCancelLineItemsByProductTypeForOrder: Maybe<Scalars['JSON']['output']>;
  /**
   * Cancel all line items within an order that match a product type
   *
   * Equivalent to POST /v1/Order/CancelMyLineItemsByProductTypeForOrder
   */
  orderPOSTCancelMyLineItemsByProductTypeForOrder: Maybe<Scalars['JSON']['output']>;
  /**
   * CancelOrderLineItem
   *
   * Equivalent to POST /v1/Order/CancelOrderLineItem
   */
  orderPOSTCancelOrderLineItem: Maybe<Scalars['JSON']['output']>;
  /**
   * CancelPendingTransaction
   *
   * Equivalent to POST /v1/Order/CancelPendingTransaction
   */
  orderPOSTCancelPendingTransaction: Maybe<Scalars['JSON']['output']>;
  /**
   * CleanUpDuplicateTransactions
   *
   * Equivalent to POST /v1/Order/CleanUpDuplicateTransactions
   */
  orderPOSTCleanUpDuplicateTransactions: Maybe<Scalars['JSON']['output']>;
  /**
   * Payment is recorder for an Order
   *
   * Equivalent to POST /v1/Order/PayOrderByProductType
   */
  orderPOSTPayOrderByProductType: Maybe<Scalars['JSON']['output']>;
  /**
   * PublishNotificationForFailedTransaction
   *
   * Equivalent to POST /v1/Order/PublishNotificationForFailedTransaction
   */
  orderPOSTPublishNotificationForPendingTransaction: Maybe<Scalars['JSON']['output']>;
  /**
   * PublishOrderEvent
   *
   * Equivalent to POST /v1/Order/PublishOrderEvent
   */
  orderPOSTPublishOrderEvent: Maybe<Scalars['JSON']['output']>;
  /**
   *
   *
   * Equivalent to POST /v1/Order/PublishOrderEventsMatchingCriteria
   */
  orderPOSTPublishOrderEventsMatchingCriteria: Maybe<Scalars['JSON']['output']>;
  /**
   * RefundCancelledOrder
   *
   * Equivalent to POST /v1/Order/RefundCancelledOrder
   */
  orderPOSTRefundCancelledOrder: Maybe<Scalars['JSON']['output']>;
  /**
   * RefundLineItemsByProductTypeForOrder
   *
   * Equivalent to POST /v1/Order/RefundLineItemsByProductTypeForOrder
   */
  orderPOSTRefundLineItemsByProductTypeForOrder: Maybe<Scalars['JSON']['output']>;
  /**
   * RefundOrderLineItem
   *
   * Equivalent to POST /v1/Order/RefundOrderLineItem
   */
  orderPOSTRefundOrderLineItem: Maybe<Scalars['JSON']['output']>;
  /**
   * RefundOrderLineItemTransaction
   *
   * Equivalent to POST /v1/Order/RefundOrderLineItemTransaction
   */
  orderPOSTRefundOrderLineItemTransaction: Maybe<Scalars['JSON']['output']>;
  /**
   * RetryFailedTransaction
   *
   * Equivalent to POST /v1/Order/RetryFailedTransaction
   */
  orderPOSTRetryFailedTransaction: Maybe<Scalars['JSON']['output']>;
  /**
   * SetOrderFulfilmentStatus
   *
   * Equivalent to POST /v1/Order/SetOrderFulfilmentStatus
   */
  orderPOSTSetOrderFulfilmentStatus: Maybe<Scalars['JSON']['output']>;
  /**
   *
   *
   * Equivalent to POST /v1/Order/UpdateAddress
   */
  orderPOSTUpdateAddress: Maybe<Scalars['JSON']['output']>;
  /**
   *
   *
   * Equivalent to POST /v1/Order/UpdateLineItems
   */
  orderPOSTUpdateLineItems: Maybe<Scalars['JSON']['output']>;
  /**
   * GetStripeCheckoutSessionToAddPaymentMethodForCurrentUser
   *
   * Equivalent to POST /v1/PaymentMethod/GetStripeCheckoutSessionToAddPaymentMethodForCurrentUser
   */
  paymentMethodPOSTGetStripeCheckoutSessionToAddPaymentMethodForCurrentUser: Maybe<GetStripeCheckoutSessionToAddPaymentMethodQueryOutput>;
  /**
   * Add a new Product to the platform.
   *
   * Equivalent to POST /v1/Product/AddProduct
   */
  productPOSTAddProduct: Maybe<Scalars['ID']['output']>;
  /**
   *
   *
   * Equivalent to POST /v1/Product/AddProductPrice
   */
  productPOSTAddProductPrice: Maybe<Scalars['ID']['output']>;
  /**
   *
   *
   * Equivalent to POST /v1/Product/AddProductProperty
   */
  productPOSTAddProductProperty: Maybe<Scalars['ID']['output']>;
  /**
   *
   *
   * Equivalent to POST /v1/Product/GetOrSetProductPrice
   */
  productPOSTGetOrSetProductPrice: Maybe<GetOrSetProductPriceCommandOutput>;
  /**
   * PublishAllProducts
   *
   * Equivalent to POST /v1/Product/PublishAllProducts
   */
  productPOSTPublishAllProducts: Maybe<Scalars['JSON']['output']>;
  /**
   * Update an existing Product
   *
   * Equivalent to POST /v1/Product/UpdateProduct
   */
  productPOSTUpdateProduct: Maybe<Scalars['JSON']['output']>;
  /**
   *
   *
   * Equivalent to POST /v1/Product/UpdateProductPriceFromPrimarySource
   */
  productPOSTUpdateProductPriceFromPrimarySource: Maybe<Scalars['JSON']['output']>;
  /**
   *
   *
   * Equivalent to POST /v1/Product/UpdateProductProperty
   */
  productPOSTUpdateProductProperty: Maybe<Scalars['JSON']['output']>;
  /**
   * CancelProductSubscription
   *
   * Equivalent to DELETE /v1/ProductSubscription/CancelProductSubscription
   */
  productSubscriptionDELETECancelProductSubscription: Maybe<Scalars['JSON']['output']>;
  /**
   * CreateProductSubscriptionAndOrderFromExisting
   *
   * Equivalent to POST /v1/ProductSubscription/CreateProductSubscriptionAndOrderFromExisting
   */
  productSubscriptionPOSTCreateProductSubscriptionAndOrderFromExisting: Maybe<Scalars['JSON']['output']>;
  /**
   * PauseProductSubscription
   *
   * Equivalent to POST /v1/ProductSubscription/PauseProductSubscription
   */
  productSubscriptionPOSTPauseProductSubscription: Maybe<Scalars['JSON']['output']>;
  /**
   * ResumeProductSubscription
   *
   * Equivalent to POST /v1/ProductSubscription/ResumeProductSubscription
   */
  productSubscriptionPOSTResumeProductSubscription: Maybe<Scalars['JSON']['output']>;
  /**
   * UpdateProductSubscriptionCustomerPaymentMethodForOrder
   *
   * Equivalent to POST /v1/ProductSubscription/UpdateProductSubscriptionCustomerPaymentMethodForOrder
   */
  productSubscriptionPOSTUpdateProductSubscriptionCustomerPaymentMethodForOrder: Maybe<UpdateProductSubscriptionCustomerPaymentMethodForOrderCommandOutput>;
  /** A5.4 setRiskAssessmentStatus */
  setRiskAssessmentStatus: Message;
  /** A4.3 updateContactsForOrganisation */
  updateContactsForOrganisation: Message;
  /** Update an invoice address */
  updateInvoiceAddress: InvoiceAddress;
  /** Update a member */
  updateMember: Member;
  /** Update a membership package */
  updateMembershipPackage: MembershipPackage;
  /** Update current membership package year */
  updateMembershipPackageYear: MembershipPackageYear;
  /** A3.2 updateOrganisationAddress */
  updateOrganisationAddress: Message;
  /** A3.1 updateOrganisationAndPolicyDeclarations */
  updateOrganisationAndPolicyDeclarations: Message;
  /** A6.1 updatePaymentForOrganisation */
  updatePaymentForOrganisation: Message;
  /** Update a PO number */
  updatePoNumber: PoNumber;
  /** A5.1 updateProgramsForOrganisation */
  updateProgramsForOrganisation: Message;
  /** Update a registration progress object for a an organisation */
  updateRegistrationProgress: RegistrationProgress;
  /** Affiliate start date for contact roles */
  updateRoleAffiliation: Message;
  /** A4.4 updateVenueDetailForOrganisation */
  updateVenueDetailForOrganisation: Message;
};

export type MutationaddInvoiceAddressArgs = {
  InvoiceAddressInput: InvoiceAddressInput;
};

export type MutationaddLastYearOrganisationInformationArgs = {
  LastYearOrganisationInformationInput: LastYearOrganisationInformationInput;
};

export type MutationaddMemberArgs = {
  memberInput: MemberInput;
};

export type MutationaddMembershipPackageArgs = {
  membershipPackageInput: MembershipPackageInput;
};

export type MutationaddPoNumberArgs = {
  PoNumberInput: PoNumberInput;
};

export type MutationaddRegistrationProgressArgs = {
  RegistrationProgressInput: RegistrationProgressInput;
};

export type MutationaddcheckoutSessionArgs = {
  checkoutSessionIDInput: CheckoutSessionIDInput;
};

export type MutationassociateManyParkCoachingOperatorsArgs = {
  contactName: InputMaybe<Scalars['String']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  operatorName: InputMaybe<Scalars['String']['input']>;
  parkSiteIds: Array<Scalars['String']['input']>;
};

export type MutationassociateManyParkCourtOperatorsArgs = {
  contactName: InputMaybe<Scalars['String']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  operatorName: InputMaybe<Scalars['String']['input']>;
  parkSiteIds: Array<Scalars['String']['input']>;
};

export type MutationassociateParkCoachingOperatorArgs = {
  contactName: InputMaybe<Scalars['String']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  operatorName: InputMaybe<Scalars['String']['input']>;
  parkSiteId: Scalars['String']['input'];
};

export type MutationassociateParkCourtOperatorArgs = {
  contactName: InputMaybe<Scalars['String']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  operatorName: InputMaybe<Scalars['String']['input']>;
  parkSiteId: Scalars['String']['input'];
};

export type MutationassociateParkOperatorArgs = {
  email: InputMaybe<Scalars['String']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
  parkSiteId: Scalars['String']['input'];
  shouldApplyToAll: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationassociateParkSiteArgs = {
  associateParkSite: AssociateParkSiteInput;
};

export type MutationbasketPOSTCheckoutAutoPaymentBasketArgs = {
  checkoutAutoPaymentBasketCommandInput: InputMaybe<CheckoutAutoPaymentBasketCommandInput>;
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
};

export type MutationbasketPOSTCheckoutBasketArgs = {
  checkoutBasketCommandInput: InputMaybe<CheckoutBasketCommandInput>;
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
};

export type MutationcreateCheckoutSessionArgs = {
  input: CheckoutSessionInput;
};

export type MutationcreateCheckoutSession_oldArgs = {
  email: InputMaybe<Scalars['String']['input']>;
  memberID: Scalars['String']['input'];
  metadata: InputMaybe<TransactionMetadataInput>;
};

export type MutationcreateContactArgs = {
  createContact: CreateContactInput;
};

export type MutationdeleteInvoiceAddressArgs = {
  id: Scalars['String']['input'];
};

export type MutationdeleteMemberArgs = {
  id: Scalars['String']['input'];
};

export type MutationdeleteMembershipPackageArgs = {
  id: Scalars['String']['input'];
};

export type MutationdeletePoNumberArgs = {
  id: Scalars['String']['input'];
};

export type MutationdeleteRegistrationProgressArgs = {
  id: Scalars['String']['input'];
};

export type MutationfulfilmentProviderPOSTAddFulfilmentProviderArgs = {
  addFulfilmentProviderCommandInput: InputMaybe<AddFulfilmentProviderCommandInput>;
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
};

export type MutationgoCardlessPOSTHandleWebhookArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
};

export type MutationgoCardlessPOSTSyncGoCardlessBillingRequestArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  syncGoCardlessBillingRequestCommandInput: InputMaybe<SyncGoCardlessBillingRequestCommandInput>;
};

export type MutationgoCardlessPOSTSyncGoCardlessPaymentsForProductSubscriptionsAndTransactionsArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  syncGoCardlessPaymentsForProductSubscriptionsAndTransactionsCommandInput: InputMaybe<SyncGoCardlessPaymentsForProductSubscriptionsAndTransactionsCommandInput>;
};

export type MutationgoCardlessPOSTSyncGoCardlessSubscriptionsArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  syncGoCardlessSubscriptionsCommandInput: InputMaybe<SyncGoCardlessSubscriptionsCommandInput>;
};

export type MutationinviteEmailArgs = {
  inviteEmail: InviteEmailInput;
};

export type MutationmarkInvoicePaymentAsPaidForMemberArgs = {
  id: Scalars['String']['input'];
  paymentDate: Scalars['DateTime']['input'];
};

export type MutationnewVenueCreationArgs = {
  newVenueCreation: NewVenueCreationInput;
};

export type MutationorderPOSTCancelDuplicateAutoPaymentTransactionsArgs = {
  cancelDuplicateAutoPaymentTransactionsCommandInput: InputMaybe<Scalars['JSON']['input']>;
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
};

export type MutationorderPOSTCancelLineItemsByProductTypeForOrderArgs = {
  cancelLineItemsByProductTypeForOrderCommandInput: InputMaybe<CancelLineItemsByProductTypeForOrderCommandInput>;
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
};

export type MutationorderPOSTCancelMyLineItemsByProductTypeForOrderArgs = {
  cancelMyLineItemsByProductTypeForOrderCommandInput: InputMaybe<CancelMyLineItemsByProductTypeForOrderCommandInput>;
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
};

export type MutationorderPOSTCancelOrderLineItemArgs = {
  cancelOrderLineItemCommandInput: InputMaybe<CancelOrderLineItemCommandInput>;
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
};

export type MutationorderPOSTCancelPendingTransactionArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  transactionId: InputMaybe<Scalars['ID']['input']>;
};

export type MutationorderPOSTCleanUpDuplicateTransactionsArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
};

export type MutationorderPOSTPayOrderByProductTypeArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  payOrderByProductTypeCommandInput: InputMaybe<PayOrderByProductTypeCommandInput>;
};

export type MutationorderPOSTPublishNotificationForPendingTransactionArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  publishNotificationForPendingTransactionCommandInput: InputMaybe<PublishNotificationForPendingTransactionCommandInput>;
};

export type MutationorderPOSTPublishOrderEventArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  publishOrderEventCommandInput: InputMaybe<PublishOrderEventCommandInput>;
};

export type MutationorderPOSTPublishOrderEventsMatchingCriteriaArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  publishOrderEventsMatchingCriteriaCommandInput: InputMaybe<PublishOrderEventsMatchingCriteriaCommandInput>;
};

export type MutationorderPOSTRefundCancelledOrderArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  refundCancelledOrderCommandInput: InputMaybe<RefundCancelledOrderCommandInput>;
};

export type MutationorderPOSTRefundLineItemsByProductTypeForOrderArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  refundLineItemsByProductTypeForOrderCommandInput: InputMaybe<RefundLineItemsByProductTypeForOrderCommandInput>;
};

export type MutationorderPOSTRefundOrderLineItemArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  refundOrderLineItemCommandInput: InputMaybe<RefundOrderLineItemCommandInput>;
};

export type MutationorderPOSTRefundOrderLineItemTransactionArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  refundOrderLineItemTransactionCommandInput: InputMaybe<RefundOrderLineItemTransactionCommandInput>;
};

export type MutationorderPOSTRetryFailedTransactionArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  transactionId: InputMaybe<Scalars['ID']['input']>;
};

export type MutationorderPOSTSetOrderFulfilmentStatusArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  setOrderFulfilmentStatusCommandInput: InputMaybe<SetOrderFulfilmentStatusCommandInput>;
};

export type MutationorderPOSTUpdateAddressArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  updateOrderAddressCommandInput: InputMaybe<UpdateOrderAddressCommandInput>;
};

export type MutationorderPOSTUpdateLineItemsArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  updateOrderLineItemsCommandInput: InputMaybe<UpdateOrderLineItemsCommandInput>;
};

export type MutationpaymentMethodPOSTGetStripeCheckoutSessionToAddPaymentMethodForCurrentUserArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  getStripeCheckoutSessionToAddPaymentMethodForCurrentUserQueryInput: InputMaybe<GetStripeCheckoutSessionToAddPaymentMethodForCurrentUserQueryInput>;
};

export type MutationproductPOSTAddProductArgs = {
  addProductCommandInput: InputMaybe<AddProductCommandInput>;
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
};

export type MutationproductPOSTAddProductPriceArgs = {
  addProductPriceCommandInput: InputMaybe<AddProductPriceCommandInput>;
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
};

export type MutationproductPOSTAddProductPropertyArgs = {
  addProductPropertyCommandInput: InputMaybe<AddProductPropertyCommandInput>;
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
};

export type MutationproductPOSTGetOrSetProductPriceArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  getOrSetProductPriceCommandInput: InputMaybe<GetOrSetProductPriceCommandInput>;
};

export type MutationproductPOSTPublishAllProductsArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  publishAllProductsCommandInput: InputMaybe<PublishAllProductsCommandInput>;
};

export type MutationproductPOSTUpdateProductArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  updateProductCommandInput: InputMaybe<UpdateProductCommandInput>;
};

export type MutationproductPOSTUpdateProductPriceFromPrimarySourceArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  updateProductPriceFromPrimarySourceCommandInput: InputMaybe<UpdateProductPriceFromPrimarySourceCommandInput>;
};

export type MutationproductPOSTUpdateProductPropertyArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  updateProductPropertyCommandInput: InputMaybe<UpdateProductPropertyCommandInput>;
};

export type MutationproductSubscriptionDELETECancelProductSubscriptionArgs = {
  cancelProductSubscriptionCommandInput: InputMaybe<CancelProductSubscriptionCommandInput>;
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
};

export type MutationproductSubscriptionPOSTCreateProductSubscriptionAndOrderFromExistingArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  createProductSubscriptionAndOrderFromExistingCommandInput: InputMaybe<CreateProductSubscriptionAndOrderFromExistingCommandInput>;
};

export type MutationproductSubscriptionPOSTPauseProductSubscriptionArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  pauseProductSubscriptionCommandInput: InputMaybe<PauseProductSubscriptionCommandInput>;
};

export type MutationproductSubscriptionPOSTResumeProductSubscriptionArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  resumeProductSubscriptionCommandInput: InputMaybe<ResumeProductSubscriptionCommandInput>;
};

export type MutationproductSubscriptionPOSTUpdateProductSubscriptionCustomerPaymentMethodForOrderArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  updateProductSubscriptionCustomerPaymentMethodForOrderCommandInput: InputMaybe<UpdateProductSubscriptionCustomerPaymentMethodForOrderCommandInput>;
};

export type MutationsetRiskAssessmentStatusArgs = {
  setRiskAssessmentStatus: SetRiskAssessmentStatusInput;
};

export type MutationupdateContactsForOrganisationArgs = {
  id: Scalars['String']['input'];
  localAuthority: InputMaybe<Scalars['Boolean']['input']>;
  updateContactsForOrganisation: UpdateContactsForOrganisationInput;
};

export type MutationupdateInvoiceAddressArgs = {
  InvoiceAddressInput: InvoiceAddressInput;
  id: Scalars['String']['input'];
};

export type MutationupdateMemberArgs = {
  id: Scalars['String']['input'];
  memberInput: MemberInput;
};

export type MutationupdateMembershipPackageArgs = {
  id: Scalars['String']['input'];
  membershipPackageInput: MembershipPackageInput;
};

export type MutationupdateMembershipPackageYearArgs = {
  membershipPackageYearInput: MembershipPackageYearInput;
};

export type MutationupdateOrganisationAddressArgs = {
  id: Scalars['String']['input'];
  updateOrganisationAddress: OrganisationAddressInput;
};

export type MutationupdateOrganisationAndPolicyDeclarationsArgs = {
  id: Scalars['String']['input'];
  updateOrganisationAndPolicyDeclarations: OrganisationAndPolicyDeclarationsInput;
};

export type MutationupdatePaymentForOrganisationArgs = {
  id: Scalars['String']['input'];
  updatePaymentForOrganisation: UpdatePaymentForOrgInput;
};

export type MutationupdatePoNumberArgs = {
  PoNumberInput: PoNumberInput;
  id: Scalars['String']['input'];
};

export type MutationupdateProgramsForOrganisationArgs = {
  id: Scalars['String']['input'];
  updateProgramsForOrganisation: UpdateProgramsForOrgInput;
};

export type MutationupdateRegistrationProgressArgs = {
  RegistrationProgressInput: RegistrationProgressInput;
  id: Scalars['String']['input'];
};

export type MutationupdateRoleAffiliationArgs = {
  affiliationInput: Array<UpdateAffiliationInput>;
};

export type MutationupdateVenueDetailForOrganisationArgs = {
  id: Scalars['String']['input'];
  updateVenueDetailForOrganisation: UpdateVenueDetailForOrgInput;
};

export type NewVenueCreation = {
  __typename?: 'NewVenueCreation';
  id: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
};

export type NewVenueCreationInput = {
  countyName: InputMaybe<Scalars['String']['input']>;
  latitude: InputMaybe<Scalars['String']['input']>;
  localAuthority: InputMaybe<Scalars['String']['input']>;
  longitude: InputMaybe<Scalars['String']['input']>;
  mailingCity: InputMaybe<Scalars['String']['input']>;
  mailingCountry: InputMaybe<Scalars['String']['input']>;
  mailingPostalCode: InputMaybe<Scalars['String']['input']>;
  mailingStreet: InputMaybe<Scalars['String']['input']>;
  mailingStreet2: InputMaybe<Scalars['String']['input']>;
  mailingStreet3: InputMaybe<Scalars['String']['input']>;
  type: InputMaybe<Scalars['String']['input']>;
  venueName: Scalars['String']['input'];
};

export type Organisation = {
  __typename?: 'Organisation';
  countyCopy: Maybe<Scalars['String']['output']>;
  countyURL: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  legalEntityType: Maybe<Scalars['String']['output']>;
  mailingAddress: MailingAddress;
  membershipNumber: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  organisationType: Maybe<Scalars['String']['output']>;
  parentLocalAuthority: Maybe<Scalars['String']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  physicalAddress: PhysicalAddress;
  tennisCounty: Maybe<Scalars['String']['output']>;
  venueName: Maybe<Scalars['String']['output']>;
  website: Maybe<Scalars['String']['output']>;
};

export type OrganisationAddressInput = {
  countyName: Scalars['String']['input'];
  deliveryAddress: Scalars['Boolean']['input'];
  latitude: Scalars['String']['input'];
  longitude: Scalars['String']['input'];
  mailingCity: InputMaybe<Scalars['String']['input']>;
  mailingCountry: Scalars['String']['input'];
  mailingPostalCode: Scalars['String']['input'];
  mailingStreet: Scalars['String']['input'];
  mailingStreet2: Scalars['String']['input'];
  mailingStreet3: Scalars['String']['input'];
  venueAddress: Scalars['Boolean']['input'];
};

export type OrganisationAndPolicyDeclarationsInput = {
  communicateDiversityInclusionPolicy: InputMaybe<Scalars['Boolean']['input']>;
  communicatedSafeguardingPolicy: InputMaybe<Scalars['Boolean']['input']>;
  confirmFullAccurateCoachDetails: InputMaybe<Scalars['Boolean']['input']>;
  deliveryContact: InputMaybe<Scalars['String']['input']>;
  diversityInclusionPolicy: InputMaybe<Scalars['Boolean']['input']>;
  educationDisclaimer: InputMaybe<Scalars['Boolean']['input']>;
  email: InputMaybe<Scalars['String']['input']>;
  employerReferenceNumber: InputMaybe<Scalars['String']['input']>;
  inaccurateCoachDetailsImplications: InputMaybe<Scalars['Boolean']['input']>;
  legalEntityType: InputMaybe<Scalars['String']['input']>;
  localAuthority: InputMaybe<Scalars['String']['input']>;
  orgName: InputMaybe<Scalars['String']['input']>;
  orgType: InputMaybe<Scalars['String']['input']>;
  phoneNumber: InputMaybe<Scalars['String']['input']>;
  riskAssessmentStatus: InputMaybe<Scalars['String']['input']>;
  safeguardingPolicy: InputMaybe<Scalars['Boolean']['input']>;
  staffDBSCheck: InputMaybe<Scalars['Boolean']['input']>;
  venueName: InputMaybe<Scalars['String']['input']>;
  website: InputMaybe<Scalars['String']['input']>;
};

export type OrganisationDto = {
  __typename?: 'OrganisationDto';
  createdDateTime: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  mobile: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  telephone: Maybe<Scalars['String']['output']>;
  updatedDateTime: Maybe<Scalars['String']['output']>;
};

export type OrganisationList = {
  __typename?: 'OrganisationList';
  organisationID: Maybe<Scalars['String']['output']>;
  organisationName: Maybe<Scalars['String']['output']>;
};

export type OrganisationListSearch = {
  __typename?: 'OrganisationListSearch';
  county: Maybe<Scalars['String']['output']>;
  ltaID: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  postcode: Maybe<Scalars['String']['output']>;
  resultsManager: Maybe<Scalars['Boolean']['output']>;
  salesforceID: Maybe<Scalars['String']['output']>;
  town: Maybe<Scalars['String']['output']>;
  type: Maybe<Scalars['String']['output']>;
};

export type OrganisationMemberExtended = {
  __typename?: 'OrganisationMemberExtended';
  contactId: Maybe<Scalars['String']['output']>;
  dateOfBirth: Maybe<Scalars['DateTime']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  gender: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  ltaNumber: Maybe<Scalars['String']['output']>;
  orgId: Maybe<Scalars['String']['output']>;
  startDate: Maybe<Scalars['DateTime']['output']>;
  type: Maybe<Scalars['String']['output']>;
  venueName: Maybe<Scalars['String']['output']>;
};

export type OrganisationMemberInfo = {
  __typename?: 'OrganisationMemberInfo';
  contactId: Maybe<Scalars['String']['output']>;
  dob: Maybe<Scalars['DateTime']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  ltaNumber: Maybe<Scalars['String']['output']>;
};

export type OrganisationMemberUpdate = {
  __typename?: 'OrganisationMemberUpdate';
  contactId: Maybe<Scalars['String']['output']>;
  dob: Maybe<Scalars['DateTime']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  ltaNumber: Maybe<Scalars['String']['output']>;
  orgId: Maybe<Scalars['String']['output']>;
  startDate: Maybe<Scalars['DateTime']['output']>;
  venueName: Maybe<Scalars['String']['output']>;
};

export enum OrganisationStatus {
  LAPSED = 'LAPSED',
  MEMBER = 'MEMBER',
  NONMEMBER = 'NONMEMBER',
}

export enum OrganisationTypes {
  CITC = 'CITC',
  Club = 'Club',
  Commercial = 'Commercial',
  Community = 'Community',
  County = 'County',
  Education = 'Education',
  EducationStudentOnly = 'EducationStudentOnly',
  LeisureCentre = 'LeisureCentre',
  LocalAuthorityFree = 'LocalAuthorityFree',
  LocalAuthorityPaid = 'LocalAuthorityPaid',
  Operator = 'Operator',
  OperatorSchool = 'OperatorSchool',
  Padel = 'Padel',
  PadelFree = 'PadelFree',
  Park = 'Park',
  ParkFree = 'ParkFree',
  ParkPaid = 'ParkPaid',
  School = 'School',
  University = 'University',
  Venue = 'Venue',
}

export enum PackageYear {
  YEAR_2019_2020 = 'YEAR_2019_2020',
  YEAR_2020_2021 = 'YEAR_2020_2021',
  YEAR_2021_2022 = 'YEAR_2021_2022',
  YEAR_2022_2023 = 'YEAR_2022_2023',
  YEAR_2023_2024 = 'YEAR_2023_2024',
  YEAR_2024_2025 = 'YEAR_2024_2025',
}

export type ParkSiteCoachingOperator = {
  __typename?: 'ParkSiteCoachingOperator';
  contactName: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  operatorName: Maybe<Scalars['String']['output']>;
};

export type ParkSiteCourtOperator = {
  __typename?: 'ParkSiteCourtOperator';
  contactName: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  operatorName: Maybe<Scalars['String']['output']>;
};

export type ParkSiteOperator = {
  __typename?: 'ParkSiteOperator';
  email: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

export type PauseProductSubscriptionCommandInput = {
  productSubscriptionId: InputMaybe<Scalars['ID']['input']>;
};

export type PayOrderByProductTypeCommandInput = {
  fulfilmentStatusId: Scalars['ID']['input'];
  ianaTimeZone: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['ID']['input'];
  payDate: Scalars['String']['input'];
  paymentMethodId: Scalars['ID']['input'];
  productTypeId: Scalars['ID']['input'];
};

export enum PaymentMethod {
  CARD = 'CARD',
  DIRECTDEBIT = 'DIRECTDEBIT',
  INVOICE = 'INVOICE',
  NOTSELECTED = 'NOTSELECTED',
}

export enum PaymentMethodAvailable {
  CARD = 'CARD',
  DIRECTDEBIT = 'DIRECTDEBIT',
  INVOICE = 'INVOICE',
}

export enum PaymentStatus {
  FAILED = 'FAILED',
  NOTPAID = 'NOTPAID',
  PAID = 'PAID',
  PENDING = 'PENDING',
}

export type PhysicalAddress = {
  __typename?: 'PhysicalAddress';
  countyName: Maybe<Scalars['String']['output']>;
  latitude: Maybe<Scalars['String']['output']>;
  localAuthority: Maybe<Scalars['String']['output']>;
  longitude: Maybe<Scalars['String']['output']>;
  mailingCity: Maybe<Scalars['String']['output']>;
  mailingCountry: Maybe<Scalars['String']['output']>;
  mailingPostalCode: Maybe<Scalars['String']['output']>;
  mailingStreet: Maybe<Scalars['String']['output']>;
  mailingStreet2: Maybe<Scalars['String']['output']>;
  mailingStreet3: Maybe<Scalars['String']['output']>;
};

/** Place details */
export type PlaceAddressDetails = {
  __typename?: 'PlaceAddressDetails';
  building_name: Scalars['String']['output'];
  building_number: Scalars['String']['output'];
  country: Scalars['String']['output'];
  county: Scalars['String']['output'];
  district: Scalars['String']['output'];
  line_1: Scalars['String']['output'];
  line_2: Scalars['String']['output'];
  line_3: Scalars['String']['output'];
  locality: Scalars['String']['output'];
  sub_building_name: Scalars['String']['output'];
  sub_building_number: Scalars['String']['output'];
  thoroughfare: Scalars['String']['output'];
  town_or_city: Scalars['String']['output'];
};

/** Place predictions */
export type PlaceDetails = {
  __typename?: 'PlaceDetails';
  address: PlaceAddressDetails;
  latitude: Scalars['String']['output'];
  longitude: Scalars['String']['output'];
  postcode: Scalars['String']['output'];
};

/** Place prediction */
export type PlacePrediction = {
  __typename?: 'PlacePrediction';
  description: Scalars['String']['output'];
  place_details: PlaceDetails;
};

/** Place predictions */
export type PlacePredictions = {
  __typename?: 'PlacePredictions';
  predictions: Array<PlacePrediction>;
};

export type PoNumber = {
  __typename?: 'PoNumber';
  id: Scalars['String']['output'];
  poNumber: Scalars['String']['output'];
};

export type PoNumberInput = {
  id: InputMaybe<Scalars['String']['input']>;
  poNumber: InputMaybe<Scalars['String']['input']>;
};

export type PolicyDeclarations = {
  __typename?: 'PolicyDeclarations';
  communicateDiversityInclusionPolicy: Maybe<Scalars['String']['output']>;
  communicatedSafeguardingPolicy: Maybe<Scalars['String']['output']>;
  confirmFullAccurateCoachDetails: Maybe<Scalars['String']['output']>;
  diversityInclusionPolicy: Maybe<Scalars['String']['output']>;
  educationDisclaimer: Maybe<Scalars['String']['output']>;
  employerReferenceNumber: Maybe<Scalars['String']['output']>;
  inaccurateCoachDetailsImplications: Maybe<Scalars['String']['output']>;
  safeguardingPolicy: Maybe<Scalars['String']['output']>;
  staffDBSCheck: Maybe<Scalars['String']['output']>;
};

/** Postal code address */
export type PostalCodeAddress = {
  __typename?: 'PostalCodeAddress';
  addresses: Array<Scalars['String']['output']>;
  latitude: Scalars['String']['output'];
  longitude: Scalars['String']['output'];
};

/** Postal code prediction */
export type PostalCodePrediction = {
  __typename?: 'PostalCodePrediction';
  description: Scalars['String']['output'];
  postcode: Scalars['String']['output'];
};

/** Postal code predictions */
export type PostalCodePredictions = {
  __typename?: 'PostalCodePredictions';
  predictions: Array<PostalCodePrediction>;
};

export enum PricingOption {
  FIXED = 'FIXED',
  FLEXIBLE = 'FLEXIBLE',
}

export enum ProcessingFeeType {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE',
}

export type ProductDto = {
  __typename?: 'ProductDto';
  createdDateTime: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  externalId: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  maxPerCustomer: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  organisation: Maybe<OrganisationDto>;
  price: Maybe<Scalars['Int']['output']>;
  productRelationships: Maybe<Array<Maybe<ProductRelationshipDto>>>;
  sku: Maybe<Scalars['String']['output']>;
  status: Maybe<ProductStatus>;
  typeId: Scalars['ID']['output'];
  updatedDateTime: Maybe<Scalars['String']['output']>;
};

export enum ProductPriceType {
  ONETIME = 'ONETIME',
  RECURRING = 'RECURRING',
}

export type ProductPropertyDto = {
  __typename?: 'ProductPropertyDto';
  files: Maybe<Array<Maybe<ProductPropertyFileDto>>>;
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
  sortIndex: Maybe<Scalars['Int']['output']>;
  values: Maybe<Array<Maybe<ProductPropertyValueDto>>>;
};

export type ProductPropertyFileDto = {
  __typename?: 'ProductPropertyFileDto';
  name: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type ProductPropertyValueDto = {
  __typename?: 'ProductPropertyValueDto';
  id: Maybe<Scalars['ID']['output']>;
  sortIndex: Maybe<Scalars['Int']['output']>;
  value: Maybe<Scalars['String']['output']>;
};

export type ProductRelationshipDto = {
  __typename?: 'ProductRelationshipDto';
  mainProduct: Maybe<ProductDto>;
  product: Maybe<ProductDto>;
};

export enum ProductStatus {
  ARCHIVED = 'ARCHIVED',
  HIDDEN = 'HIDDEN',
  PUBLIC = 'PUBLIC',
}

export type PublishAllProductsCommandInput = {
  organisationIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  productStatuses: InputMaybe<Array<InputMaybe<ProductStatus>>>;
  productTypeIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type PublishNotificationForPendingTransactionCommandInput = {
  transactionId: InputMaybe<Scalars['ID']['input']>;
};

export type PublishOrderEventCommandInput = {
  eventType: InputMaybe<Scalars['String']['input']>;
  orderId: InputMaybe<Scalars['ID']['input']>;
};

export type PublishOrderEventsMatchingCriteriaCommandInput = {
  createdDateTimeMax: InputMaybe<Scalars['String']['input']>;
  createdDateTimeMin: InputMaybe<Scalars['String']['input']>;
  mustHaveActiveProductSubscriptionPaymentProviderIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  mustHaveChargeTransactionOrderPaymentStatus: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  mustHaveChargeTransactionPaymentProviderId: InputMaybe<Scalars['ID']['input']>;
  mustHaveNewProductSubscriptions: InputMaybe<Scalars['Boolean']['input']>;
};

export type Query = {
  __typename?: 'Query';
  /** Auto-complete places */
  autocompletePlaces: PlacePredictions;
  /** Auto-complete poste code */
  autocompletePostalCode: PostalCodePredictions;
  /**
   * Get the status of a basket
   *
   * Equivalent to GET /v1/Basket/BasketStatus
   */
  basketGETBasketStatus: Maybe<BasketStatusQueryOutput>;
  /** checkMainContact */
  canContactBeRemoved: Scalars['Boolean']['output'];
  /**
   * File countries that match the search text.
   *
   * Equivalent to GET /v1/Countries/FindMatching
   */
  countriesGETFindMatching: Maybe<GetCountriesQueryOutput>;
  /**
   * Get all countries
   *
   * Equivalent to GET /v1/Countries
   */
  countriesGETGetCountries: Maybe<GetCountriesQueryOutput>;
  /**
   *
   *
   * Equivalent to GET /v1/Countries/GetDefaultCountry
   */
  countriesGETGetDefaultCountry: Maybe<GetCountriesQueryOutput>;
  /** Get an address for a postcode code and house number */
  findHouse: PostalCodeAddress;
  /** Get a list addresses and location for a postcode */
  findPostalCode: PostalCodeAddress;
  /**
   *
   *
   * Equivalent to GET /v1/FulfilmentProvider/GetFulfilmentProviders
   */
  fulfilmentProviderGETGetFulfilmentProviders: Maybe<GetProductsByStatusQueryOutput>;
  /** A4.1 getContactsForOrganisation */
  getContactsForOrganisation: Array<GetContactsForOrganisation>;
  /** A5.3 getHowdensURL */
  getHowdensURL: GetHowdensURL;
  /** getParkCoachingOperator */
  getParkCoachingOperator: ParkSiteCoachingOperator;
  /** getParkCourtOperator */
  getParkCourtOperator: ParkSiteCourtOperator;
  /** getParkOperator */
  getParkOperator: ParkSiteOperator;
  /** A6.3 getParkSite */
  getParkSite: Array<GetParkSite>;
  /** A4.5 getProgramsForOrganisation */
  getProgramsForOrganisation: GetProgramsForOrg;
  /** Get start date affiliation for contact roles */
  getRoleAffiliation: Array<UpdatedAffiliation>;
  /**
   *
   *
   * Equivalent to GET /v1/GoCardless/GetGoCardlessPaymentDetails
   */
  goCardlessGETGetGoCardlessPaymentDetails: Maybe<GetGoCardlessPaymentDetailsQueryOutput>;
  /**
   *
   *
   * Equivalent to GET /v1/GoCardless/GetGoCardlessPaymentDetailsForOrder
   */
  goCardlessGETGetGoCardlessPaymentDetailsForOrder: Maybe<GetGoCardlessPaymentDetailsForOrderQueryOutput>;
  /**
   *
   *
   * Equivalent to GET /v1/GoCardless/GetGoCardlessSubscriptionDetails
   */
  goCardlessGETGetGoCardlessSubscriptionDetails: Maybe<GetGoCardlessSubscriptionDetailsQueryOutput>;
  /**
   *
   *
   * Equivalent to GET /v1/GoCardless/GetGoCardlessSubscriptionDetailsForOrder
   */
  goCardlessGETGetGoCardlessSubscriptionDetailsForOrder: Maybe<GetGoCardlessSubscriptionDetailsForOrderQueryOutput>;
  /**
   * ProcessGoCardlessEventsForAccount
   *
   * Equivalent to GET /v1/GoCardless/ProcessGoCardlessEventsForAccount
   */
  goCardlessGETProcessGoCardlessEventsForAccount: Maybe<Scalars['JSON']['output']>;
  /** Get an invoice address */
  invoiceAddress: InvoiceAddress;
  lastYearOrganisationInformation: LastYearOrganisationInformation;
  lastYearorganisationInformationBySalesforceID: LastYearOrganisationInformation;
  /** List all members */
  listMember: Array<Member>;
  /** Get a list of all membership packages */
  listMembershipPackage: Array<MembershipPackage>;
  /** Logout from LTA SSO */
  logout: Scalars['Boolean']['output'];
  /** Get a member */
  member: Member;
  /** Get list of members for a membership package ID */
  memberMembershipPackage: SearchMember;
  /** Get a member using a Salesforce ID */
  memberOrganisation: Member;
  /** Get a member for previous membership package year */
  memberOrganisationLastYear: Member;
  /** 	A2.3 getLTAMembers */
  members: Array<OrganisationMemberExtended>;
  /** A3.3 getLTAMembersForOrganisation */
  membersByOrg: LTAMembersForOrganisation;
  /** Get a membership package by id */
  membershipPackage: MembershipPackage;
  /** Get a membership package by name for current package year */
  membershipPackageByName: MembershipPackage;
  /** Get membership package of an organisation */
  membershipPackageByOrganisation: MembershipPackage;
  /** Get membership package for by organisation type for current package year */
  membershipPackageByOrganisationType: MembershipPackage;
  /** Get a membership package by name for current package year */
  membershipPackageByProductID: MembershipPackage;
  /** Get list of membership packages for a member over years */
  membershipPackageMember: Array<MembershipPackageMember>;
  /** Get current membership package year */
  membershipPackageYear: MembershipPackageYear;
  /**
   * CanCancelledOrderBeRefunded
   *
   * Equivalent to GET /v1/Order/CanCancelledOrderBeRefunded
   */
  orderGETCanCancelledOrderBeRefunded: Maybe<CanBeRefundedQueryOutput>;
  /**
   * Can order be refunded
   *
   * Equivalent to GET /v1/Order/CanOrderBeRefunded
   */
  orderGETCanOrderBeRefunded: Maybe<CanBeRefundedQueryOutput>;
  /**
   * CanOrderLineItemBeCancelled
   *
   * Equivalent to GET /v1/Order/CanOrderLineItemBeCancelled
   */
  orderGETCanOrderLineItemBeCancelled: Maybe<CanBeCancelledQueryOutput>;
  /**
   * Can order line item be refunded
   *
   * Equivalent to GET /v1/Order/CanOrderLineItemBeRefunded
   */
  orderGETCanOrderLineItemBeRefunded: Maybe<CanBeRefundedQueryOutput>;
  /**
   * CanOrderLineItemsByProductTypeBeCancelled
   *
   * Equivalent to GET /v1/Order/CanOrderLineItemsByProductTypeBeCancelled
   */
  orderGETCanOrderLineItemsByProductTypeBeCancelled: Maybe<CanBeCancelledQueryOutput>;
  /**
   *
   *
   * Equivalent to GET /v1/Order/CanOrderLineItemsByProductTypeBePaidCashOutput
   */
  orderGETCanOrderLineItemsByProductTypeBePaid: Maybe<CanOrderLineItemsByProductTypeBePaidCashOutput>;
  /**
   * CanOrderLineItemsByProductTypeBeRefunded
   *
   * Equivalent to GET /v1/Order/CanOrderLineItemsByProductTypeBeRefunded
   */
  orderGETCanOrderLineItemsByProductTypeBeRefunded: Maybe<CanBeRefundedQueryOutput>;
  /**
   * Can order line item transaction be refunded
   *
   * Equivalent to GET /v1/Order/CanRefundOrderLineItemTransaction
   */
  orderGETCanRefundOrderLineItemTransaction: Maybe<CanBeRefundedQueryOutput>;
  /**
   *
   *
   * Equivalent to GET /v1/Order/CanTransactionBeCancelled
   */
  orderGETCanTransactionBeCancelled: Maybe<CanBeCancelledQueryOutput>;
  /**
   * FindByStatus
   *
   * Equivalent to GET /v1/Order/findByStatus
   */
  orderGETFindByStatus: Maybe<GetOrdersQueryOutput>;
  /**
   * FindOrdersForProductTypeByStatus
   *
   * Equivalent to GET /v1/Order/FindOrdersForProductTypeByStatus
   */
  orderGETFindOrdersForProductTypeByStatus: Maybe<GetOrdersForProductTypeQueryOutput>;
  /**
   *
   *
   * Equivalent to GET /v1/Order/GetMyOrderForProductType
   */
  orderGETGetMyOrderForProductType: Maybe<GetOrderForProductTypeQueryOutputOrder>;
  /**
   *
   *
   * Equivalent to GET /v1/Order/GetMyOrders
   */
  orderGETGetMyOrders: Maybe<GetOrdersQueryOutput>;
  /**
   *
   *
   * Equivalent to GET /v1/Order/GetMyOrdersForProductType
   */
  orderGETGetMyOrdersForProductType: Maybe<GetOrdersForProductTypeQueryOutput>;
  /**
   * GetOrderById
   *
   * Equivalent to GET /v1/Order/{id}
   */
  orderGETGetOrderById: Maybe<GetOrderByIdQueryOutput>;
  /**
   * Get order by order line item id
   *
   * Equivalent to GET /v1/Order/ByOrderLineItemId/{id}
   */
  orderGETGetOrderByOrderLineItemId: Maybe<GetOrderByIdQueryOutput>;
  /**
   * GetOrderForProductTypeById
   *
   * Equivalent to GET /v1/Order/GetOrderForProductTypeById
   */
  orderGETGetOrderForProductTypeById: Maybe<GetOrderForProductTypeByIdQueryOutput>;
  /**
   * GetOrderLineItemDetailsForProductType
   *
   * Equivalent to GET /v1/Order/GetOrderLineItemDetailsForProductType
   */
  orderGETGetOrderLineItemDetailsForProductType: Maybe<GetOrderLineItemDetailsForProductTypeQueryOutput>;
  /**
   * Get all order pay status values
   *
   * Equivalent to GET /v1/Order/OrderPayStatusValues
   */
  orderGETGetOrderPayStatusValues: Maybe<GetOrderPayStatusValuesQueryOutput>;
  /**
   * Get Products Related To Order
   *
   * Equivalent to GET /v1/Order/{id}/GetOrderProducts
   */
  orderGETGetOrderProducts: Maybe<GetOrderProductsQueryOutput>;
  /**
   * GetOrdersExportDataAsCsvFile
   *
   * Equivalent to GET /v1/Order/GetOrdersExportDataAsCsvFile
   */
  orderGETGetOrdersExportDataAsCsvFile: Maybe<Scalars['String']['output']>;
  /**
   * GetOrdersWithProductSubscriptions
   *
   * Equivalent to GET /v1/Order/GetOrdersWithProductSubscriptions
   */
  orderGETGetOrdersWithProductSubscriptions: Maybe<GetOrdersWithProductSubscriptionsQueryOutput>;
  /**
   * Get Transactions For Order filtered by product type
   *
   * Equivalent to GET /v1/Order/GetTransactionsByProductTypeForOrder
   */
  orderGETGetTransactionsByProductTypeForOrder: Maybe<GetTransactionsByProductTypeForOrderQueryOutput>;
  /**
   * OrderFulfilmentStatusValues
   *
   * Equivalent to GET /v1/Order/OrderFulfilmentStatusValues
   */
  orderGETOrderFulfilmentStatusValues: Maybe<GetOrderFulfilmentStatusValuesQueryOutput>;
  /** A2.1 getOrganisationDetails */
  organisation: Organisation;
  /** A1.1 getOrganisationListByContact */
  organisationList: Array<OrganisationList>;
  /** AllPlaces database search */
  organisationListSearch: Array<OrganisationListSearch>;
  /**
   * GetCustomerPaymentMethodsForCurrentUser
   *
   * Equivalent to GET /v1/PaymentMethod/GetCustomerPaymentMethodsForCurrentUser
   */
  paymentMethodGETGetCustomerPaymentMethodsForCurrentUser: Maybe<GetCustomerPaymentMethodsQueryOutput>;
  /**
   * GetPaymentMethodManagementUrlCurrentUser
   *
   * Equivalent to GET /v1/PaymentMethod/GetPaymentMethodManagementUrlCurrentUser
   */
  paymentMethodGETGetPaymentMethodManagementUrlCurrentUser: Maybe<GetPaymentMethodManagementUrlCurrentUserQueryOutput>;
  /**
   * GetPaymentMethods
   *
   * Equivalent to GET /v1/PaymentMethods
   */
  paymentMethodGETGetPaymentMethods: Maybe<GetPaymentMethodsQueryOutput>;
  /** Get a PO number */
  poNumber: PoNumber;
  /** A3.5 getPolicyDeclarations */
  policyDeclarations: PolicyDeclarations;
  /**
   * Get a list of active child products associated with the parent product
   *
   * Equivalent to GET /v1/Product/GetChildProductsByParentProductId
   */
  productGETGetChildProductsByParentProductId: Maybe<GetChildProductsByParentProductIdQueryOutput>;
  /**
   *
   *
   * Equivalent to GET /v1/Product/GetHierarchyChildrenByProductId
   */
  productGETGetHierarchyChildrenByProductId: Maybe<GetHierarchyChildrenByProductIdQueryOutput>;
  /**
   * GetProRataAmountForProductUsingAuthenticationContext
   *
   * Equivalent to GET /v1/Product/GetProRataAmountForProductUsingAuthenticationContext
   */
  productGETGetProRataAmountForProductUsingAuthenticationContext: Maybe<GetProRataAmountForProductQueryOutput>;
  /**
   * Returns a single Product
   *
   * Equivalent to GET /v1/Product/{productId}
   */
  productGETGetProductById: Maybe<GetProductByIdQueryOutput>;
  /**
   *
   *
   * Equivalent to GET /v1/Product/Property/{productPropertyId}
   */
  productGETGetProductPropertyById: Maybe<ProductPropertyDto>;
  /**
   * Finds Product by status
   *
   * Equivalent to GET /v1/Product/GetProductsByStatus
   */
  productGETGetProductsByStatus: Maybe<GetProductsByStatusQueryOutput>;
  /**
   * Finds Product by type
   *
   * Equivalent to GET /v1/Product/GetProductsByType
   */
  productGETGetProductsByType: Maybe<GetProductsByTypeQueryOutput>;
  /**
   * GetStripeProRataAmountForProductUsingAuthenticationContext
   *
   * Equivalent to GET /v1/Product/GetStripeProRataAmountForProductUsingAuthenticationContext
   */
  productGETGetStripeProRataAmountForProductUsingAuthenticationContext: Maybe<GetProRataAmountForProductQueryOutput>;
  /**
   * Return if the parent product has active child products associated with it
   *
   * Equivalent to GET /v1/ProductRelationship/{parentProductId}
   */
  productRelationshipGETGetBasicChildProductsInfoByParentProductId: Maybe<GetBasicChildProductsInfoByParentProductIdQueryOutput>;
  /** Gets coach and welfare officers' validation for registered organisations */
  registeredOrganisationValidationFlag: Array<GetValidationFlags>;
  /** Get organisation's registration progress object */
  registrationProgress: RegistrationProgress;
  /** Get a list of registration progress for all organisations */
  registrationProgressList: Array<RegistrationProgress>;
  /** Search by coach code */
  searchByCoachCodeOrLtaNumber: Array<OrganisationMemberInfo>;
  /** A2.3 getLTAMembers */
  searchLTAMembers: Array<OrganisationMemberUpdate>;
  /** Search member records */
  searchMembers: SearchMember;
  /** Get list of members for a membership package ID */
  searchMembersByName: SearchMember;
  /** Get list of members for a membership package ID passing a payment reference number */
  searchMembersByPaymentReference: SearchMember;
  /** Get user information from LTA SSO */
  user: User;
  /** Get user information from LTA SSO with an access token */
  userAccessToken: User;
  /** A2.2 getVenueDetailForOrganisation */
  venues: Venue;
};

export type QueryautocompletePlacesArgs = {
  place: Scalars['String']['input'];
};

export type QueryautocompletePostalCodeArgs = {
  postcode: Scalars['String']['input'];
};

export type QuerybasketGETBasketStatusArgs = {
  classicUserId: InputMaybe<Scalars['ID']['input']>;
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  idempotencyKey: InputMaybe<Scalars['ID']['input']>;
};

export type QuerycanContactBeRemovedArgs = {
  contactId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type QuerycountriesGETFindMatchingArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  searchText: InputMaybe<Scalars['String']['input']>;
};

export type QuerycountriesGETGetCountriesArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
};

export type QuerycountriesGETGetDefaultCountryArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  query: InputMaybe<Scalars['JSON']['input']>;
};

export type QueryfindHouseArgs = {
  house: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
};

export type QueryfindPostalCodeArgs = {
  postcode: Scalars['String']['input'];
};

export type QueryfulfilmentProviderGETGetFulfilmentProvidersArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygetContactsForOrganisationArgs = {
  id: Scalars['String']['input'];
  key: ContactsForOrgKey;
  localAuthority: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerygetHowdensURLArgs = {
  id: Scalars['String']['input'];
};

export type QuerygetParkCoachingOperatorArgs = {
  parkSiteId: Scalars['String']['input'];
};

export type QuerygetParkCourtOperatorArgs = {
  parkSiteId: Scalars['String']['input'];
};

export type QuerygetParkOperatorArgs = {
  parkSiteId: Scalars['String']['input'];
};

export type QuerygetParkSiteArgs = {
  id: Scalars['String']['input'];
};

export type QuerygetProgramsForOrganisationArgs = {
  id: Scalars['String']['input'];
};

export type QuerygetRoleAffiliationArgs = {
  contactId: Scalars['String']['input'];
  key: ContactsForOrgKey;
  orgId: Scalars['String']['input'];
};

export type QuerygoCardlessGETGetGoCardlessPaymentDetailsArgs = {
  accountId: InputMaybe<Scalars['ID']['input']>;
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  paymentId: InputMaybe<Scalars['String']['input']>;
};

export type QuerygoCardlessGETGetGoCardlessPaymentDetailsForOrderArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  orderId: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygoCardlessGETGetGoCardlessSubscriptionDetailsArgs = {
  accountId: InputMaybe<Scalars['ID']['input']>;
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  subscriptionId: InputMaybe<Scalars['String']['input']>;
};

export type QuerygoCardlessGETGetGoCardlessSubscriptionDetailsForOrderArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  orderId: InputMaybe<Scalars['ID']['input']>;
};

export type QuerygoCardlessGETProcessGoCardlessEventsForAccountArgs = {
  accountId: InputMaybe<Scalars['ID']['input']>;
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  createdAtMax: InputMaybe<Scalars['String']['input']>;
  createdAtMin: InputMaybe<Scalars['String']['input']>;
};

export type QueryinvoiceAddressArgs = {
  id: Scalars['String']['input'];
};

export type QuerylastYearOrganisationInformationArgs = {
  id: Scalars['String']['input'];
};

export type QuerylastYearorganisationInformationBySalesforceIDArgs = {
  salesforceID: Scalars['String']['input'];
};

export type QuerylistMembershipPackageArgs = {
  packageYear: InputMaybe<PackageYear>;
};

export type QuerylogoutArgs = {
  authToken: Scalars['String']['input'];
};

export type QuerymemberArgs = {
  id: Scalars['String']['input'];
};

export type QuerymemberMembershipPackageArgs = {
  id: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  paymentStatusList: InputMaybe<Array<PaymentStatus>>;
  skip: Scalars['Int']['input'];
};

export type QuerymemberOrganisationArgs = {
  salesforceID: Scalars['String']['input'];
};

export type QuerymemberOrganisationLastYearArgs = {
  salesforceID: Scalars['String']['input'];
};

export type QuerymembersArgs = {
  endIndex: Scalars['Int']['input'];
  id: Scalars['String']['input'];
  localAuthority: InputMaybe<Scalars['Boolean']['input']>;
  startIndex: Scalars['Int']['input'];
};

export type QuerymembersByOrgArgs = {
  id: Scalars['String']['input'];
  localAuthority: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerymembershipPackageArgs = {
  id: Scalars['String']['input'];
};

export type QuerymembershipPackageByNameArgs = {
  name: Scalars['String']['input'];
};

export type QuerymembershipPackageByOrganisationArgs = {
  salesforceID: Scalars['String']['input'];
};

export type QuerymembershipPackageByOrganisationTypeArgs = {
  organisationType: Scalars['String']['input'];
};

export type QuerymembershipPackageByProductIDArgs = {
  productID: Scalars['String']['input'];
};

export type QuerymembershipPackageMemberArgs = {
  memberID: Scalars['String']['input'];
  membershipPackageID: Scalars['String']['input'];
};

export type QueryorderGETCanCancelledOrderBeRefundedArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  orderRefundId: InputMaybe<Scalars['ID']['input']>;
};

export type QueryorderGETCanOrderBeRefundedArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  orderId: InputMaybe<Scalars['ID']['input']>;
};

export type QueryorderGETCanOrderLineItemBeCancelledArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  orderLineItemId: InputMaybe<Scalars['ID']['input']>;
  quantity: InputMaybe<Scalars['Int']['input']>;
};

export type QueryorderGETCanOrderLineItemBeRefundedArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  orderLineItemId: InputMaybe<Scalars['ID']['input']>;
  quantity: InputMaybe<Scalars['Int']['input']>;
  venueAccountIdOverride: InputMaybe<Scalars['ID']['input']>;
};

export type QueryorderGETCanOrderLineItemsByProductTypeBeCancelledArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  orderId: InputMaybe<Scalars['ID']['input']>;
  productTypeId: InputMaybe<Scalars['ID']['input']>;
};

export type QueryorderGETCanOrderLineItemsByProductTypeBePaidArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  orderId: Scalars['ID']['input'];
  productTypeId: Scalars['ID']['input'];
};

export type QueryorderGETCanOrderLineItemsByProductTypeBeRefundedArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  orderId: InputMaybe<Scalars['ID']['input']>;
  productTypeId: InputMaybe<Scalars['ID']['input']>;
};

export type QueryorderGETCanRefundOrderLineItemTransactionArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  orderLineItemTransactionId: InputMaybe<Scalars['ID']['input']>;
};

export type QueryorderGETCanTransactionBeCancelledArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  transactionId: InputMaybe<Scalars['ID']['input']>;
};

export type QueryorderGETFindByStatusArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  index: InputMaybe<Scalars['Float']['input']>;
  orderFulfilmentStatusIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  orderPayStatusIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  pageNumber: InputMaybe<Scalars['Int']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  sortBy: InputMaybe<SortBy>;
};

export type QueryorderGETFindOrdersForProductTypeByStatusArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  index: InputMaybe<Scalars['Float']['input']>;
  orderFulfilmentStatusIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  orderIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  orderPayStatusIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  organisationIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  pageNumber: InputMaybe<Scalars['Int']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  productTypeId: InputMaybe<Scalars['ID']['input']>;
  sortBy: InputMaybe<SortBy>;
  userId: InputMaybe<Scalars['ID']['input']>;
  venueContactIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryorderGETGetMyOrderForProductTypeArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  orderId: InputMaybe<Scalars['ID']['input']>;
  productTypeId: InputMaybe<Scalars['ID']['input']>;
};

export type QueryorderGETGetMyOrdersArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  index: InputMaybe<Scalars['Float']['input']>;
  pageNumber: InputMaybe<Scalars['Int']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  sortBy: InputMaybe<SortBy>;
};

export type QueryorderGETGetMyOrdersForProductTypeArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  pageNumber: InputMaybe<Scalars['Int']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  productTypeId: InputMaybe<Scalars['ID']['input']>;
  sortBy: InputMaybe<SortBy>;
};

export type QueryorderGETGetOrderByIdArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type QueryorderGETGetOrderByOrderLineItemIdArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type QueryorderGETGetOrderForProductTypeByIdArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  orderId: InputMaybe<Scalars['ID']['input']>;
  productTypeId: InputMaybe<Scalars['ID']['input']>;
};

export type QueryorderGETGetOrderLineItemDetailsForProductTypeArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  orderId: InputMaybe<Scalars['ID']['input']>;
  productTypeId: InputMaybe<Scalars['ID']['input']>;
};

export type QueryorderGETGetOrderPayStatusValuesArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
};

export type QueryorderGETGetOrderProductsArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  productTypeId: InputMaybe<Scalars['ID']['input']>;
};

export type QueryorderGETGetOrdersExportDataAsCsvFileArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  customDateFormat: InputMaybe<Scalars['String']['input']>;
  customTimeFormat: InputMaybe<Scalars['String']['input']>;
  ianaTimeZone: InputMaybe<Scalars['String']['input']>;
  orderFulfilmentStatusIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  organisationId: InputMaybe<Scalars['ID']['input']>;
  productTypeId: InputMaybe<Scalars['ID']['input']>;
};

export type QueryorderGETGetOrdersWithProductSubscriptionsArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  maxNumberOfTransactions: InputMaybe<Scalars['Int']['input']>;
  orderIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QueryorderGETGetTransactionsByProductTypeForOrderArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  orderId: InputMaybe<Scalars['ID']['input']>;
  productTypeId: InputMaybe<Scalars['ID']['input']>;
};

export type QueryorderGETOrderFulfilmentStatusValuesArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
};

export type QueryorganisationArgs = {
  id: Scalars['String']['input'];
};

export type QueryorganisationListArgs = {
  loginName: Scalars['String']['input'];
};

export type QueryorganisationListSearchArgs = {
  name: Scalars['String']['input'];
  types: InputMaybe<Array<OrganisationTypes>>;
};

export type QuerypaymentMethodGETGetCustomerPaymentMethodsForCurrentUserArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  paymentMethodId: InputMaybe<Scalars['ID']['input']>;
  productId: InputMaybe<Scalars['ID']['input']>;
};

export type QuerypaymentMethodGETGetPaymentMethodManagementUrlCurrentUserArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  paymentMethodId: InputMaybe<Scalars['ID']['input']>;
};

export type QuerypaymentMethodGETGetPaymentMethodsArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
};

export type QuerypoNumberArgs = {
  id: Scalars['String']['input'];
};

export type QuerypolicyDeclarationsArgs = {
  id: Scalars['String']['input'];
};

export type QueryproductGETGetChildProductsByParentProductIdArgs = {
  childProductTypeId: InputMaybe<Scalars['ID']['input']>;
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  parentProductId: Scalars['ID']['input'];
};

export type QueryproductGETGetHierarchyChildrenByProductIdArgs = {
  childProductTypeId: InputMaybe<Scalars['ID']['input']>;
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  productId: Scalars['ID']['input'];
};

export type QueryproductGETGetProRataAmountForProductUsingAuthenticationContextArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  installmentAmount: InputMaybe<Scalars['Int']['input']>;
  installmentCount: InputMaybe<Scalars['Int']['input']>;
  paymentMethodId: InputMaybe<Scalars['ID']['input']>;
  productId: InputMaybe<Scalars['ID']['input']>;
  transactionDay: InputMaybe<Scalars['Int']['input']>;
};

export type QueryproductGETGetProductByIdArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  productId: Scalars['ID']['input'];
};

export type QueryproductGETGetProductPropertyByIdArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  productPropertyId: Scalars['ID']['input'];
};

export type QueryproductGETGetProductsByStatusArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  productTypeId: InputMaybe<Scalars['ID']['input']>;
  status: Array<InputMaybe<ProductStatus>>;
};

export type QueryproductGETGetProductsByTypeArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  pageNumber: InputMaybe<Scalars['Int']['input']>;
  pageSize: InputMaybe<Scalars['Int']['input']>;
  productName: InputMaybe<Scalars['String']['input']>;
  statuses: InputMaybe<Array<InputMaybe<ProductStatus>>>;
  typeId: Scalars['ID']['input'];
};

export type QueryproductGETGetStripeProRataAmountForProductUsingAuthenticationContextArgs = {
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  installmentAmount: InputMaybe<Scalars['Int']['input']>;
  installmentCount: InputMaybe<Scalars['Int']['input']>;
  productId: InputMaybe<Scalars['ID']['input']>;
  transactionDay: InputMaybe<Scalars['Int']['input']>;
};

export type QueryproductRelationshipGETGetBasicChildProductsInfoByParentProductIdArgs = {
  childProductTypeId: InputMaybe<Scalars['ID']['input']>;
  clubSparkOrganisationId: InputMaybe<Scalars['ID']['input']>;
  parentProductId: Scalars['ID']['input'];
  whenParentProductHasNoPaymentMethodOnlyReturnFreeProducts: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryregisteredOrganisationValidationFlagArgs = {
  contactID: Scalars['String']['input'];
};

export type QueryregistrationProgressArgs = {
  id: Scalars['String']['input'];
};

export type QueryregistrationProgressListArgs = {
  ids: Array<Scalars['String']['input']>;
};

export type QuerysearchByCoachCodeOrLtaNumberArgs = {
  coachCode: InputMaybe<Scalars['String']['input']>;
  ltaNumber: InputMaybe<Scalars['String']['input']>;
};

export type QuerysearchLTAMembersArgs = {
  id: Scalars['String']['input'];
  ignoreAffiliation?: InputMaybe<Scalars['Boolean']['input']>;
  localAuthority?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

export type QuerysearchMembersArgs = {
  searchMembersInput: SearchMembersInput;
};

export type QuerysearchMembersByNameArgs = {
  limit: Scalars['Int']['input'];
  membershipPackageID: Scalars['String']['input'];
  name: Scalars['String']['input'];
  skip: Scalars['Int']['input'];
};

export type QuerysearchMembersByPaymentReferenceArgs = {
  limit: Scalars['Int']['input'];
  membershipPackageID: Scalars['String']['input'];
  paymentReference: Scalars['String']['input'];
  skip: Scalars['Int']['input'];
};

export type QueryuserArgs = {
  authCode: Scalars['String']['input'];
};

export type QueryuserAccessTokenArgs = {
  accessToken: Scalars['String']['input'];
};

export type QueryvenuesArgs = {
  id: Scalars['String']['input'];
};

export type RefundCancelledOrderCommandInput = {
  ianaTimeZone: InputMaybe<Scalars['String']['input']>;
  orderFulfilmentStatusId: InputMaybe<Scalars['ID']['input']>;
  orderRefundId: InputMaybe<Scalars['ID']['input']>;
  payDate: InputMaybe<Scalars['String']['input']>;
  paymentMethodId: InputMaybe<Scalars['ID']['input']>;
  reverseTransfer: InputMaybe<Scalars['Boolean']['input']>;
};

export type RefundLineItemsByProductTypeForOrderCommandInput = {
  ianaTimeZone: InputMaybe<Scalars['String']['input']>;
  orderFulfilmentStatusId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
  payDate: InputMaybe<Scalars['String']['input']>;
  paymentMethodId: Scalars['ID']['input'];
  productTypeId: Scalars['ID']['input'];
};

export type RefundOrderLineItemCommandInput = {
  ianaTimeZone: InputMaybe<Scalars['String']['input']>;
  orderFulfilmentStatusId: InputMaybe<Scalars['ID']['input']>;
  orderLineItemId: InputMaybe<Scalars['ID']['input']>;
  payDate: InputMaybe<Scalars['String']['input']>;
  paymentMethodId: InputMaybe<Scalars['ID']['input']>;
  quantity: InputMaybe<Scalars['Int']['input']>;
  reverseTransfer: InputMaybe<Scalars['Boolean']['input']>;
  venueAccountIdOverride: InputMaybe<Scalars['ID']['input']>;
};

export type RefundOrderLineItemTransactionCommandInput = {
  existingOrderLineItemTransactionId: InputMaybe<Scalars['ID']['input']>;
  ianaTimeZone: InputMaybe<Scalars['String']['input']>;
  idempotencyKey: InputMaybe<Scalars['ID']['input']>;
  newOrderLineItemTransactionId: InputMaybe<Scalars['ID']['input']>;
  payDate: InputMaybe<Scalars['String']['input']>;
  paymentMethodId: InputMaybe<Scalars['ID']['input']>;
  reverseTransfer: InputMaybe<Scalars['Boolean']['input']>;
};

export enum RefundType {
  CANCELLATION = 'CANCELLATION',
  REFUND = 'REFUND',
}

export type RegistrationProgress = {
  __typename?: 'RegistrationProgress';
  id: Scalars['String']['output'];
  progress: Scalars['String']['output'];
};

export type RegistrationProgressInput = {
  progress: InputMaybe<Scalars['String']['input']>;
};

export type ResumeProductSubscriptionCommandInput = {
  productSubscriptionId: InputMaybe<Scalars['ID']['input']>;
};

/** Search for members information */
export type SearchMember = {
  __typename?: 'SearchMember';
  memberCount: Maybe<Scalars['Int']['output']>;
  memberList: Maybe<Array<Member>>;
};

/** Search Member records */
export type SearchMembersInput = {
  addedDate: InputMaybe<Scalars['DateTime']['input']>;
  currentPackageYearNotPaid: InputMaybe<Scalars['Boolean']['input']>;
  currentPackageYearSum: InputMaybe<Scalars['Boolean']['input']>;
  paymentDate: InputMaybe<Scalars['DateTime']['input']>;
};

export type SetOrderFulfilmentStatusCommandInput = {
  orderFulfilmentStatusId: InputMaybe<Scalars['ID']['input']>;
  orderId: InputMaybe<Scalars['ID']['input']>;
};

export type SetRiskAssessmentStatusInput = {
  /** Date need to be in this format 2020-10-22 14:45:24 */
  completedDate: Scalars['String']['input'];
  /** Salesforce ID */
  id: Scalars['String']['input'];
  membershipNumber: Scalars['String']['input'];
  riskAssessmentStatus: Scalars['String']['input'];
};

export enum SortBy {
  CREATEDDATETIMEASC = 'CREATEDDATETIMEASC',
  CREATEDDATETIMEDESC = 'CREATEDDATETIMEDESC',
}

export type Subscription = {
  __typename?: 'Subscription';
  annualFee: Maybe<Scalars['Int']['output']>;
  category: Scalars['String']['output'];
  joiningFee: Maybe<Scalars['Int']['output']>;
  numberOfMen: Maybe<Scalars['Int']['output']>;
  numberOfWomen: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionInput = {
  annualFee: InputMaybe<Scalars['Int']['input']>;
  category: Scalars['String']['input'];
  joiningFee: InputMaybe<Scalars['Int']['input']>;
  numberOfMen: InputMaybe<Scalars['Int']['input']>;
  numberOfWomen: InputMaybe<Scalars['Int']['input']>;
};

export type SyncGoCardlessBillingRequestCommandInput = {
  createdDateTimeMax: InputMaybe<Scalars['String']['input']>;
  createdDateTimeMin: InputMaybe<Scalars['String']['input']>;
  ignoreNextRunTimespan: InputMaybe<Scalars['Boolean']['input']>;
  includeNonPendingTransactions: InputMaybe<Scalars['Boolean']['input']>;
  orderIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type SyncGoCardlessPaymentsForProductSubscriptionsAndTransactionsCommandInput = {
  createdDateTimeMax: InputMaybe<Scalars['String']['input']>;
  createdDateTimeMin: InputMaybe<Scalars['String']['input']>;
  ignoreNextRunTimespan: InputMaybe<Scalars['Boolean']['input']>;
  includeExpiredSubscriptions: InputMaybe<Scalars['Boolean']['input']>;
  includeNonPendingTransactions: InputMaybe<Scalars['Boolean']['input']>;
  includeSubscriptionTransactions: InputMaybe<Scalars['Boolean']['input']>;
  orderIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type SyncGoCardlessSubscriptionsCommandInput = {
  createdDateTimeMax: InputMaybe<Scalars['String']['input']>;
  createdDateTimeMin: InputMaybe<Scalars['String']['input']>;
  ignoreNextRunTimespan: InputMaybe<Scalars['Boolean']['input']>;
  orderIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type TotalDetails = {
  __typename?: 'TotalDetails';
  amount_discount: Maybe<Scalars['Float']['output']>;
  amount_tax: Maybe<Scalars['Float']['output']>;
};

export type TransactionMetadataInput = {
  membershipNumber: InputMaybe<Scalars['String']['input']>;
  organisationID: InputMaybe<Scalars['String']['input']>;
  organisationName: InputMaybe<Scalars['String']['input']>;
  parentCounty: InputMaybe<Scalars['String']['input']>;
  salesforceID: InputMaybe<Scalars['String']['input']>;
  sfContactID: InputMaybe<Scalars['String']['input']>;
};

export type TransactionMetadataOutput = {
  __typename?: 'TransactionMetadataOutput';
  organisationName: Maybe<Scalars['String']['output']>;
};

export type UpdateAffiliationInput = {
  ContactId: Scalars['String']['input'];
  OrgId: Scalars['String']['input'];
  Role: Scalars['String']['input'];
  StartDate: Scalars['String']['input'];
};

export type UpdateContactsForOrganisationInput = {
  contactId: Scalars['String']['input'];
  hours: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  newRole: Array<Scalars['String']['input']>;
  previousRole: Array<Scalars['String']['input']>;
  status: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrderAddressCommandInput = {
  address1: InputMaybe<Scalars['String']['input']>;
  address2: InputMaybe<Scalars['String']['input']>;
  address3: InputMaybe<Scalars['String']['input']>;
  countryId: InputMaybe<Scalars['ID']['input']>;
  county: InputMaybe<Scalars['String']['input']>;
  orderId: InputMaybe<Scalars['ID']['input']>;
  postcode: InputMaybe<Scalars['String']['input']>;
  town: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrderItemsCommandLineItemInput = {
  id: InputMaybe<Scalars['ID']['input']>;
  productId: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateOrderLineItemsCommandInput = {
  lineItems: InputMaybe<Array<InputMaybe<UpdateOrderItemsCommandLineItemInput>>>;
  orderId: InputMaybe<Scalars['ID']['input']>;
};

export type UpdatePaymentForOrgInput = {
  action: UpdateType;
  amountPaid: Scalars['String']['input'];
  contactId: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
  formSubmittedDate: Scalars['String']['input'];
  gracePeriodDate: Scalars['String']['input'];
  id: Scalars['String']['input'];
  packageType: Scalars['String']['input'];
  paymentDate: Scalars['String']['input'];
  paymentMethod: Scalars['String']['input'];
  paymentReference: Scalars['String']['input'];
  paymentStatus: Scalars['String']['input'];
  renewalDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

export type UpdateProductCommandFileInput = {
  name: InputMaybe<Scalars['String']['input']>;
  url: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProductCommandInput = {
  description: InputMaybe<Scalars['String']['input']>;
  externalId: InputMaybe<Scalars['String']['input']>;
  files: InputMaybe<Array<InputMaybe<UpdateProductCommandFileInput>>>;
  groupIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id: Scalars['ID']['input'];
  maxPerCustomer: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  price: InputMaybe<Scalars['Int']['input']>;
  productParentIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  productTypeId: Scalars['ID']['input'];
  propertyValueIds: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  sku: InputMaybe<Scalars['String']['input']>;
  status: InputMaybe<ProductStatus>;
  updatedDateTime: InputMaybe<Scalars['String']['input']>;
  variantGroup: InputMaybe<UpdateProductVariantGroupInput>;
};

export type UpdateProductPriceFromPrimarySourceCommandInput = {
  pricesToUpdate: InputMaybe<Array<InputMaybe<UpdateProductPriceFromPrimarySourceCommandPriceToUpdateInput>>>;
  productId: InputMaybe<Scalars['ID']['input']>;
  updateAll: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateProductPriceFromPrimarySourceCommandPriceToUpdateInput = {
  customKey: InputMaybe<Scalars['String']['input']>;
  isRenewal: InputMaybe<Scalars['Boolean']['input']>;
  productPriceType: InputMaybe<ProductPriceType>;
};

export type UpdateProductPropertyCommandFileInput = {
  name: InputMaybe<Scalars['String']['input']>;
  url: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProductPropertyCommandInput = {
  files: InputMaybe<Array<InputMaybe<UpdateProductPropertyCommandFileInput>>>;
  id: Scalars['ID']['input'];
  input: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  required: InputMaybe<Scalars['Boolean']['input']>;
  sortIndex: InputMaybe<Scalars['Int']['input']>;
  values: InputMaybe<Array<InputMaybe<UpdateProductPropertyCommandValueInput>>>;
};

export type UpdateProductPropertyCommandValueInput = {
  sortIndex: InputMaybe<Scalars['Int']['input']>;
  value: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProductSubscriptionCustomerPaymentMethodForOrderCommandInput = {
  customerPaymentMethodExternalId: Scalars['String']['input'];
  orderId: Scalars['ID']['input'];
};

export type UpdateProductSubscriptionCustomerPaymentMethodForOrderCommandOutput = {
  __typename?: 'UpdateProductSubscriptionCustomerPaymentMethodForOrderCommandOutput';
  createProductSubscriptionResponse: Maybe<CreateProductSubscriptionResponse>;
};

export type UpdateProductVariantGroupInput = {
  id: InputMaybe<Scalars['ID']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  sortIndex: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateProgramsForOrgFacilityInput = {
  action: UpdateType;
  facilityName: Scalars['String']['input'];
  facilityType: Scalars['String']['input'];
  summerSession: Scalars['Boolean']['input'];
  winterSession: Scalars['Boolean']['input'];
};

export type UpdateProgramsForOrgInput = {
  facilities: Array<UpdateProgramsForOrgFacilityInput>;
  subscriptions: Array<UpdateProgramsForOrgSubscriptionInput>;
};

export type UpdateProgramsForOrgSubscriptionInput = {
  numberOfMen: InputMaybe<Scalars['Int']['input']>;
  numberOfWomen: InputMaybe<Scalars['Int']['input']>;
  programCategory: Scalars['String']['input'];
};

export enum UpdateType {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  UPDATE = 'UPDATE',
}

export type UpdateVenueDetailForOrgFacilityInput = {
  action: UpdateType;
  facilityName: Scalars['String']['input'];
  facilityType: Scalars['String']['input'];
  location: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
  surface: Scalars['String']['input'];
};

export type UpdateVenueDetailForOrgInput = {
  facilities: Array<UpdateVenueDetailForOrgFacilityInput>;
  subscriptions: Array<SubscriptionInput>;
};

export type UpdatedAffiliation = {
  __typename?: 'UpdatedAffiliation';
  ContactId: Scalars['String']['output'];
  OrgId: Scalars['String']['output'];
  Role: Scalars['String']['output'];
  StartDate: Maybe<Scalars['String']['output']>;
};

/** User */
export type User = {
  __typename?: 'User';
  accessToken: Scalars['String']['output'];
  age: Maybe<Scalars['String']['output']>;
  contactPersonalAddress: Maybe<Array<ContactPersonalAddress>>;
  currencySymbol: Maybe<Scalars['String']['output']>;
  dob: Maybe<Scalars['DateTime']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  gender: Maybe<Gender>;
  lastName: Maybe<Scalars['String']['output']>;
  listOfAccount: Maybe<Array<ListOfAccount>>;
  playCounty: Maybe<Scalars['String']['output']>;
  postcode: Maybe<Scalars['String']['output']>;
  /** Salesforce ContactID */
  sfContactID: Maybe<Scalars['String']['output']>;
  userEmail: Maybe<Scalars['String']['output']>;
  userFullName: Maybe<Scalars['String']['output']>;
  userID: Maybe<Scalars['String']['output']>;
  userName: Scalars['String']['output'];
};

/** Organisation Venue */
export type Venue = {
  __typename?: 'Venue';
  facilities: Maybe<Array<Facility>>;
  subscriptions: Maybe<Array<Subscription>>;
};

export type memberQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type memberQuery = {
  __typename?: 'Query';
  member: {
    __typename?: 'Member';
    courtCount: number | null;
    grassCourtCount: number | null;
    id: string;
    isNew: boolean;
    membershipPackageID: string;
    packageYear: number;
    paymentDate: string;
    paymentMethod: PaymentMethod;
    paymentReference: string | null;
    paymentStatus: PaymentStatus;
    salesforceID: string;
    status: OrganisationStatus;
    totalCost: number | null;
  };
};

export type membershipPackageMemberQueryVariables = Exact<{
  memberID: Scalars['String']['input'];
  membershipPackageID: Scalars['String']['input'];
}>;

export type membershipPackageMemberQuery = {
  __typename?: 'Query';
  membershipPackageMember: Array<{
    __typename?: 'MembershipPackageMember';
    member: {
      __typename?: 'Member';
      courtCount: number | null;
      grassCourtCount: number | null;
      id: string;
      isNew: boolean;
      membershipPackageID: string;
      packageYear: number;
      paymentDate: string;
      paymentMethod: PaymentMethod;
      paymentReference: string | null;
      paymentStatus: PaymentStatus;
      salesforceID: string;
      status: OrganisationStatus;
      totalCost: number | null;
    } | null;
    membershipPackage: {
      __typename?: 'MembershipPackage';
      code: string;
      courtCap: number | null;
      courtCost: number | null;
      description: string;
      endDate: string;
      gracePeriodDate: string;
      grassCourtCost: number | null;
      id: string;
      maxPrice: number | null;
      name: string;
      organisationType: string;
      packageYear: number;
      paymentMethodAvailable: Array<PaymentMethodAvailable>;
      pricingOption: PricingOption;
      processingFee: number | null;
      processingFeeType: ProcessingFeeType | null;
      renewalPeriod: MembershipPackageRenewalPeriod;
      renewsOn: string;
      startDate: string;
      status: MembershipPackageStatus;
      type: string;
    } | null;
  }>;
};

export type membershipPackageQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type membershipPackageQuery = {
  __typename?: 'Query';
  membershipPackage: {
    __typename?: 'MembershipPackage';
    code: string;
    courtCap: number | null;
    courtCost: number | null;
    description: string;
    benefits: string;
    confirmation: string;
    endDate: string;
    gracePeriodDate: string;
    grassCourtCost: number | null;
    id: string;
    maxPrice: number | null;
    name: string;
    organisationType: string;
    packageYear: number;
    paymentMethodAvailable: Array<PaymentMethodAvailable>;
    pricingOption: PricingOption;
    processingFee: number | null;
    processingFeeType: ProcessingFeeType | null;
    renewalPeriod: MembershipPackageRenewalPeriod;
    renewsOn: string;
    startDate: string;
    status: MembershipPackageStatus;
    type: string;
  };
};

export type memberMembershipPackageQueryVariables = Exact<{
  id: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
  paymentStatusList: InputMaybe<Array<PaymentStatus> | PaymentStatus>;
}>;

export type memberMembershipPackageQuery = {
  __typename?: 'Query';
  memberMembershipPackage: {
    __typename?: 'SearchMember';
    memberCount: number | null;
    memberList: Array<{
      __typename?: 'Member';
      courtCount: number | null;
      grassCourtCount: number | null;
      id: string;
      isNew: boolean;
      membershipPackageID: string;
      name: string | null;
      packageYear: number;
      paymentMethod: PaymentMethod;
      paymentReference: string | null;
      paymentStatus: PaymentStatus;
      salesforceID: string;
      status: OrganisationStatus;
      totalCost: number | null;
    }> | null;
  };
};

export type searchQueryVariables = Exact<{
  name: Scalars['String']['input'];
  membershipPackageID: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
}>;

export type searchQuery = {
  __typename?: 'Query';
  searchMembersByName: {
    __typename?: 'SearchMember';
    memberCount: number | null;
    memberList: Array<{
      __typename?: 'Member';
      courtCount: number | null;
      grassCourtCount: number | null;
      id: string;
      isNew: boolean;
      membershipPackageID: string;
      name: string | null;
      packageYear: number;
      paymentMethod: PaymentMethod;
      paymentReference: string | null;
      paymentStatus: PaymentStatus;
      salesforceID: string;
      status: OrganisationStatus;
      totalCost: number | null;
    }> | null;
  };
};

export type searchMembersByPaymentReferenceQueryVariables = Exact<{
  paymentReference: Scalars['String']['input'];
  membershipPackageID: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
}>;

export type searchMembersByPaymentReferenceQuery = {
  __typename?: 'Query';
  searchMembersByPaymentReference: {
    __typename?: 'SearchMember';
    memberCount: number | null;
    memberList: Array<{
      __typename?: 'Member';
      courtCount: number | null;
      grassCourtCount: number | null;
      id: string;
      isNew: boolean;
      membershipPackageID: string;
      name: string | null;
      packageYear: number;
      paymentMethod: PaymentMethod;
      paymentReference: string | null;
      paymentStatus: PaymentStatus;
      salesforceID: string;
      status: OrganisationStatus;
      totalCost: number | null;
    }> | null;
  };
};

export type updateMemberMutationVariables = Exact<{
  id: Scalars['String']['input'];
  memberInput: MemberInput;
}>;

export type updateMemberMutation = {
  __typename?: 'Mutation';
  updateMember: {
    __typename?: 'Member';
    id: string;
    grassCourtCount: number | null;
    courtCount: number | null;
    totalCost: number | null;
    membershipPackageID: string;
    paymentMethod: PaymentMethod;
    paymentStatus: PaymentStatus;
  };
};

export type markInvoiceAsPaidForMemberMutationVariables = Exact<{
  id: Scalars['String']['input'];
  paymentDate: Scalars['DateTime']['input'];
}>;

export type markInvoiceAsPaidForMemberMutation = {
  __typename?: 'Mutation';
  markInvoicePaymentAsPaidForMember: {
    __typename?: 'Member';
    id: string;
    grassCourtCount: number | null;
    courtCount: number | null;
    totalCost: number | null;
    membershipPackageID: string;
    paymentMethod: PaymentMethod;
    paymentStatus: PaymentStatus;
  };
};

export type UpdateMembershipPackageMutationVariables = Exact<{
  id: Scalars['String']['input'];
  membershipPackageInput: MembershipPackageInput;
}>;

export type UpdateMembershipPackageMutation = {
  __typename?: 'Mutation';
  updateMembershipPackage: { __typename?: 'MembershipPackage'; name: string; confirmation: string };
};

export type listMembershipPackageQueryVariables = Exact<{
  packageYear: InputMaybe<PackageYear>;
}>;

export type listMembershipPackageQuery = {
  __typename?: 'Query';
  listMembershipPackage: Array<{
    __typename?: 'MembershipPackage';
    code: string;
    endDate: string;
    id: string;
    name: string;
    renewsOn: string;
    organisationType: string;
    startDate: string;
    status: MembershipPackageStatus;
    type: string;
    packageYear: number;
  }>;
};

export type userQueryVariables = Exact<{
  authCode: Scalars['String']['input'];
}>;

export type userQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    accessToken: string;
    userName: string;
    userID: string | null;
    userEmail: string | null;
    sfContactID: string | null;
  };
};

export type logoutQueryVariables = Exact<{
  authCode: Scalars['String']['input'];
}>;

export type logoutQuery = { __typename?: 'Query'; logout: boolean };

export type membershipPackageYearQueryVariables = Exact<{ [key: string]: never }>;

export type membershipPackageYearQuery = {
  __typename?: 'Query';
  membershipPackageYear: { __typename?: 'MembershipPackageYear'; packageYear: number };
};

export const memberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'member' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'member' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'courtCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'grassCourtCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isNew' } },
                { kind: 'Field', name: { kind: 'Name', value: 'membershipPackageID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'packageYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentReference' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'salesforceID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCost' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<memberQuery, memberQueryVariables>;
export const membershipPackageMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'membershipPackageMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'memberID' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'membershipPackageID' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'membershipPackageMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'memberID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'memberID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'membershipPackageID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'membershipPackageID' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'member' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'courtCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'grassCourtCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isNew' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'membershipPackageID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packageYear' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentReference' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'salesforceID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCost' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'membershipPackage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'courtCap' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'courtCost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gracePeriodDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'grassCourtCost' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'organisationType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packageYear' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodAvailable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pricingOption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'processingFee' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'processingFeeType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'renewalPeriod' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'renewsOn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<membershipPackageMemberQuery, membershipPackageMemberQueryVariables>;
export const membershipPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'membershipPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'membershipPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'courtCap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'courtCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'benefits' } },
                { kind: 'Field', name: { kind: 'Name', value: 'confirmation' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gracePeriodDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'grassCourtCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organisationType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'packageYear' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentMethodAvailable' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pricingOption' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processingFee' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processingFeeType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'renewalPeriod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'renewsOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<membershipPackageQuery, membershipPackageQueryVariables>;
export const memberMembershipPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'memberMembershipPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentStatusList' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaymentStatus' } } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'memberMembershipPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentStatusList' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentStatusList' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'memberList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'courtCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'grassCourtCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isNew' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'membershipPackageID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packageYear' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentReference' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'salesforceID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCost' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'memberCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<memberMembershipPackageQuery, memberMembershipPackageQueryVariables>;
export const searchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'search' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'membershipPackageID' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchMembersByName' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'membershipPackageID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'membershipPackageID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'memberCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'memberList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'courtCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'grassCourtCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isNew' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'membershipPackageID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packageYear' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentReference' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'salesforceID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCost' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<searchQuery, searchQueryVariables>;
export const searchMembersByPaymentReferenceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'searchMembersByPaymentReference' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentReference' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'membershipPackageID' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchMembersByPaymentReference' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentReference' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentReference' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'membershipPackageID' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'membershipPackageID' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'memberCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'memberList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'courtCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'grassCourtCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isNew' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'membershipPackageID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'packageYear' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentReference' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'paymentStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'salesforceID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCost' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<searchMembersByPaymentReferenceQuery, searchMembersByPaymentReferenceQueryVariables>;
export const updateMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'memberInput' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'MemberInput' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'memberInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'memberInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'grassCourtCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'courtCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'membershipPackageID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentStatus' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<updateMemberMutation, updateMemberMutationVariables>;
export const markInvoiceAsPaidForMemberDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'markInvoiceAsPaidForMember' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'paymentDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'markInvoicePaymentAsPaidForMember' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paymentDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'paymentDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'grassCourtCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'courtCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCost' } },
                { kind: 'Field', name: { kind: 'Name', value: 'membershipPackageID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paymentStatus' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<markInvoiceAsPaidForMemberMutation, markInvoiceAsPaidForMemberMutationVariables>;
export const UpdateMembershipPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMembershipPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'membershipPackageInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'MembershipPackageInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateMembershipPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'membershipPackageInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'membershipPackageInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'confirmation' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateMembershipPackageMutation, UpdateMembershipPackageMutationVariables>;
export const listMembershipPackageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'listMembershipPackage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'packageYear' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PackageYear' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'listMembershipPackage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'packageYear' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'packageYear' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'renewsOn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'organisationType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'packageYear' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<listMembershipPackageQuery, listMembershipPackageQueryVariables>;
export const userDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'user' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'authCode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'authCode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'authCode' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sfContactID' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<userQuery, userQueryVariables>;
export const logoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'logout' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'authCode' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logout' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'authToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'authCode' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<logoutQuery, logoutQueryVariables>;
export const membershipPackageYearDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'membershipPackageYear' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'membershipPackageYear' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'packageYear' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<membershipPackageYearQuery, membershipPackageYearQueryVariables>;
